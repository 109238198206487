/**
* material-scrolltop
*
* Author: Bartholomej
* Website: https://github.com/bartholomej/material-scrolltop
* Docs: https://github.com/bartholomej/material-scrolltop
* Repo: https://github.com/bartholomej/material-scrolltop
* Issues: https://github.com/bartholomej/material-scrolltop/issues
*/

$mst-color: $blue; // Primary background color (deafualt: $primary-color)
$mst-gutter: 23px; // Gap from corner (default: $column-gutter)
$mst-button-size: 56px; // Size of circle button (default: rem-calc(56))
$mst-icon: true !default; // Set false if you want to use your own sign directly inside html span element

// ==========================================================================
//   Mixins for material-scrolltop
// ==========================================================================

@mixin prefixer($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefixer(transform, translate(-50%, -50%));
}

// ==========================================================================
//   Stylesheet
// ==========================================================================

.material-scrolltop {
  z-index: 1000;
  display: block;
  position: fixed;
  width: 0;
  height: 0;
  bottom: $mst-gutter;
  right: $mst-gutter;
  padding: 0;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  background: $mst-color;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include prefixer(transition, all 0.3s cubic-bezier(0.25, 0.25, 0, 1));
  @if $mst-icon {
    @extend %mst-icon;
  }

  &:hover {
    cursor: pointer;
    background-color: $mst-color;
    text-decoration: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5), 0 3px 15px rgba(0, 0, 0, 0.5);
  }

  &::before {
    @include centerer;
    content: "";
    width: 0;
    border-radius: 100%;
    background: mix($mst-color, #fff, 85%);
    @if $mst-icon {
      @extend %mst-icon;
    }
  }

  &:active::before {
    width: 120%;
    padding-top: 120%;
    @include prefixer(transition, all 0.2s ease-out);
  }

  &.reveal {
    width: $mst-button-size;
    height: $mst-button-size;
  }

  outline: none !important;

  span {
    display: block;
    font-size: 25px;
    color: #fff;
  }
}

%mst-icon {
  background-image: url(https://www.1001audios.fr/assets/images/top-arrow.svg);
  background-position: center 50%;
  background-repeat: no-repeat;
}
