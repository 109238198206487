.backoffice-nav {
    display: block;
    margin-top: 3px;
    background: white;
    text-align: center;
    .nav-item {
        padding: 0 14px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        color: $color-brand-gray;
        &:hover {
            color: $color-brand-gray;
            border-bottom: 3px solid $color-brand-blue-logo;
        }
        &.active {
            border-bottom: 3px solid $color-brand-blue-logo;
        }
    }
    /*     position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 200; */
}