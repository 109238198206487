body.pricing {
    .logo-container {
        position: relative;
        margin-top: 14px;
    }
    .logo {
        width: 200px;
        .cls-logo-1,
        .cls-logo-2 {
            fill: $color-light;
        }
    }
    .call-us {
        color: $color-light;
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 300;
        strong {
            font-weight: 400;
        }
    }
    .screens {
        width: 80vw;
        height: 55vh;
        background-position: center;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        margin: auto;
        z-index: 1000;
        bottom: 0;
        max-height: 340px;
        margin-left: 10vw;
    }
    h2 {
        font-size: 1.7em;
        font-weight: 300;
    }
    /*     .header {
        background-color: transparent;
        box-shadow: none;
        top: 0;
        width: 100%;
        .header--inner {
            #logo {
                svg {
                    height: auto;
                    width: auto;
                    .cls-logo-1,
                    .cls-logo-2 {
                        fill: $color-light;
                    }
                }
            }
            .mainnav {
                background-color: transparent;
                @include media-breakpoint-down(md) {
                    background-color: transparent;
                    .navbar-toggler {
                        border: 1px solid #FFF;
                        span {
                            background-color: #FFF;
                        }
                    }
                }
                ul {
                    li {
                        a:not(.dropdown-item) {
                            color: $color-light;
                            @include media-breakpoint-down(md) {
                                color: $color-brand-gray
                            }
                            &.border--btn {
                                border: 1px solid $color-light;
                            }
                            &:after {
                                background-color: $color-light;
                            }
                            &:hover {
                                color: $color-light;
                            }
                        }
                    }
                }
            }
        }
    } */
    .carousel-item {
        background-size: cover;
        background-position: center center;
        height: 100%;
        .carousel-caption {
            height: 170px;
            top: 200px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            .title-container {
                margin-top: -150px;
            }
            h1 {
                color: $color-light;
                @include media-breakpoint-down(md) {
                    font-size: 30px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 27px;
                }
            }
            h2 {
                color: $color-light;
                @include media-breakpoint-down(md) {
                    font-size: 25px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                }
            }
        }
        .container {
            height: 90vh;
            min-height: 420px;
        }
    }
    .home--slider {
        padding: 0;
        height: 90vh;
        min-height: 390px;
    }
    .home--intro {
        text-align: center;
        padding: 30px 0;
        &--item {
            padding: 0 ($spacer * 2.5);
            margin-top: 25px;
            img {
                max-width: 42px;
            }
            p {
                margin-top: 20px;
                font-size: 15px;
                @media screen and (max-width: 570px) {
                    font-size: 16px;
                }
                color: $color-brand-gray;
                font-weight: normal;
                line-height: 20px;
            }
        }
        h3 {
            font-size: 23px;
            font-size: 1.35rem;
            font-weight: 400;
            color: $gray-dark;
        }
    }
    .compte-pro {
        h2 {
            font-weight: 400;
            color: $gray-dark;
        }
        background-color: $color-brand-gray-light;
        img {
            max-width: 100%;
            -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
            -moz-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
            box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
        }
        p {
            font-size: 16px;
        }
        ul {
            list-style-type: none;
            padding: 0;
            li {
                font-size: 16px;
            }
        }
    }
    .trust {
        img {
            max-height: 120px;
            margin-left: 10px;
            margin-right: 33px;
            @include media-breakpoint-down(md) {
                margin-right: 10px;
            }
        }
    }
    .faq {
        background-color: $color-brand-gray-light;
        h5 {
            font-weight: 400;
            color: $gray-dark;
        }
    } // .vertical-center-container {
    //     position: relative;
    //     .vertical-center {
    //         position: absolute;
    //         top: 50%;
    //         /* poussé de la moitié de hauteur du référent */
    //         transform: translateY(-50%);
    //         /* tiré de la moitié de sa propre hauteur */
    //     }
    // }
    .logos-container {
        min-height: 200px;
        align-items: center;
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
        }
        @include media-breakpoint-down(md) {
            text-align: center;
            img {
                margin-top: 20px;
            }
        }
    }

    .create-account-button{
        border: 1px solid #435f71;
        transition: background-color 0.3s, color 0.3s;
        color: #435f71;
        font-size: 15px;
        position: relative;
        padding: 8px 16px;
        border-width: 2px !important;
        border-radius: 25px !important;

        &:hover{
            background-color:#435f71;
            color:white;
            text-decoration: none;
        }
    }

    .gray-link{
        color: #435f71;
    }
}

.subtitle {
    font-size: 1.7rem;
}