.audition-title>h2{
    color: #435f71;
    font-weight: 500;
}

.article-image-legend{
    font-size: 80%;
    opacity: 0.85;
}

.article-image-container{
    img{
        max-height: 200px;
    }
    text-align: center;
}