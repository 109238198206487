body.home {
  h2,
  .h2 {
    font-size: 1.7em;
  }
  .h2 {
    font-size: 1.7em;
    line-height: 1.1;
    margin-bottom: 0.5rem;
  }
  .header {
    background-color: transparent;
    box-shadow: none;
    position: fixed;
    top: 0;
    width: 100%;
    transition: background-color 0.3s, color 0.3s;
    .header--inner {
      #logo {
        svg {
          height: auto;
          width: auto;
          .cls-logo-1,
          .cls-logo-2 {
            fill: $color-light;
          }
        }
      }
      .mainnav {
        background-color: transparent;
        @include media-breakpoint-down(md) {
          background-color: transparent;
          .navbar-toggler {
            border: 1px solid #fff;
            span {
              background-color: #fff;
            }
          }
        }
        ul {
          li {
            a:not(.dropdown-item) {
              transition: background-color 0.3s, color 0.3s;
              color: $color-light;
              @include media-breakpoint-down(md) {
                color: $color-brand-gray;
              }
              &.border--btn {
                border: 1px solid $color-light;
              }
              &:after {
                background-color: $color-light;
              }
              &:hover {
                color: $color-light;
              }
            }
          }
        }
      }
    }
    &.scrolled {
      opacity: 0.92;
      background-color: #fff;
      height: 58px;
      display: flex;
      align-items: center;
      box-shadow: 0 2px 10px 0 rgba(67, 95, 113, 0.2);
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
      .container {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      .header--inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
          display: inline-block;
          width: 100%;
        }
        #logo {
          width: 190px;
          height: auto;
          position: relative;
          top: 2px;
          @include media-breakpoint-down(md) {
            float: left;
          }
          svg {
            height: auto;
            width: auto;
            .cls-logo-2 {
              fill: $color-brand-blue;
            }
            .cls-logo-1 {
              fill: $color-brand-green;
            }
          }
        }
        .mainnav {
          // background-color: #fff;
          padding-right: 0;
          @include media-breakpoint-down(md) {
            background-color: transparent;
            .navbar-toggler {
              margin-top: -18px;
              border: 1px solid $color-brand-gray;
              padding: 5px 10px;
              span {
                background-color: $color-brand-gray;
                width: 25px;
                height: 2px;
                display: block;
                margin: 6px 0;
              }
            }
            .navbar-collapse {
              background-color: #fff;
              padding: 20px;
              position: fixed;
              width: 100%;
              left: 0;
              top: 58px;
              text-align: center;
            }
          }
          ul {
            margin: 0;
            padding: 0;
            @include media-breakpoint-down(md) {
              align-items: center;
            }
            li {
              list-style: none;
              display: inline-flex;
              a:not(.dropdown-item) {
                padding: 8px 16px;
                color: $color-brand-gray;
                font-size: 15px;
                @media screen and (max-width: 570px) {
                  font-size: 16px;
                }
                position: relative;
                &.border--btn {
                  border: 1px solid $color-brand-gray;
                  border-radius: 4px;
                  margin-left: 16px;
                  &:after {
                    display: none;
                  }
                }
                &:after {
                  content: "";
                  position: absolute;
                  left: 16px;
                  bottom: 0px;
                  width: calc(100% - 32px);
                  height: 2px;
                  background-color: $color-brand-gray;
                  opacity: 0;
                }
                &:not(.my-account-dropdown) {
                  &:hover {
                    color: $color-brand-gray;
                    &:not(.flag-link):after {
                      opacity: 1;
                      @include media-breakpoint-down(md) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .home--slider {
    padding: 0;
  }
  .home--intro {
    text-align: center;
    padding: 30px 0;
    &--item {
      padding: 0 ($spacer * 2.5);
      margin-top: 25px;
      p {
        margin-top: 20px;
        font-size: 15px;
        @media screen and (max-width: 570px) {
          font-size: 16px;
        }
        color: $color-brand-gray;
        font-weight: normal;
        line-height: 20px;
      }
    }
  }
  .home--hero {
    padding-bottom: 0;
    ul {
      margin: 0;
      padding-left: 17px;
      li {
        padding-top: 5px;
      }
    }
    .text-right {
      @include media-breakpoint-down(md) {
        text-align: left !important;
      }
    }
  }
  .home--evaluate {
    background-color: white;
  }
  .home--info {
    background-color: white;
    padding-bottom: 40px;

    .separator {
      width: 400px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      border-top: 2px solid #eee;
      margin: auto;
      position: relative;
      top: 50px;
    }
  }
  .home--gammes {
    margin-bottom: 87px;
    .card-home {
      overflow: hidden;
      position: relative;
      margin-bottom: 10px;
      box-shadow: 0 15px 35px 0 rgba(14, 58, 91, 0.1);
      border-radius: 6px;
      .card-text {
        min-height: 140px;
        background-color: white;
        bottom: 0;
        margin-top: 0;
        position: relative;
        padding: 20px;
        /*         &:before {
          border-bottom: 35px solid white;
          border-right: 400px solid transparent;
          content: "";
          height: 0;
          left: 0;
          position: absolute;
          top: -35px;
          width: 0;
        } */
      }
    }
  }
  .home--audioprothésiste {
    background-position: 0% 40%;
    background-size: cover;
    text-align: center;
    p {
      color: #fff;
    }
    h3,
    .fake-h3 {
      color: #fff;
    }
    .btn {
      border: 1px solid #fff;
      color: #fff;
      background-color: transparent;
    }
  }
  #test-auditif {
    .resultat {
      opacity: 0.5;
      &.fini {
        opacity: 1;
      }
    }
    .js-question {
      margin-left: 5px;
      margin-right: 5px;
      opacity: 0.5;
      &.current {
        opacity: 1;
      }
    }
    .pagination {
      text-align: center;
      display: block;
    }
  }
  .search-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 154px;
  }
  .search-header {
    text-align: center;
  }
  form.search {
    text-align: center;
  }
  .carousel-caption {
    z-index: 0;
    text-align: left;

    @include media-breakpoint-down(sm) {
      bottom: 71px;
    }

    left: 0;
    display: block !important;
    margin-top: 150px !important;
    position: initial !important;

    h2,
    .h2 {
      color: white;
      font-size: 18px;
      margin-top: 50px;
    }
  }

  .carousel-caption,
  .grayed-area {
    max-width: 700px;
  }

  .carousel-inner {
    background-color: $color-brand-gray-light;
  }
  .search-info {
    color: #fff !important;
    font-size: 17px !important;
    letter-spacing: normal !important;
    margin: 0 !important;
    line-height: 1.42857 !important;
  }
  #search-button {
    min-width: 150px;
    height: 50px;
    cursor: pointer;
  }
  .ages {
    margin-top: 35px;
    padding-left: 0;
    font-weight: normal;
    color: $gray;
    list-style: none;
    display: flex;
    justify-content: space-around;
    li {
      display: block;
      border: 1px solid #fff;
      border-radius: 6px;
      box-shadow: 0 15px 35px 0 rgba(14, 58, 91, 0.2);
      padding: 15px;
      padding-bottom: 25px;
      width: 300px;
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;

      .age-title {
        font-size: 16px;
        font-weight: bold;
        display: block;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 15px;
        min-height: 60px;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
      li {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  #address-input {
    border-color: white;
  }
  body.home .home--slider {
    padding: 0;
    height: 100vh;
  }
  .search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // max-width: 760px;
    margin: auto;
    /*     @include media-breakpoint-down(sm) {
      max-width: 500px;
      margin: auto;
    } */
    select,
    input {
      flex-grow: 1;
    }
    .audiopro-type-selector {
      height: 75px;
      border: none;
      border-radius: 0;
      border-right: 1px solid #ccc;
      max-width: 170px;
      cursor: pointer;

      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    .input-lg {
      height: 75px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    #locate-me-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -5px;
      height: 75px;
      background-color: #f5faff;
      color: $color-brand-gray;
      font-size: 15px;
      @media screen and (max-width: 570px) {
        font-size: 16px;
      }
      &.disabled,
      &:disabled {
        cursor: default !important;
      }
      @include media-breakpoint-down(sm) {
        .locate-me-text {
          display: none;
        }
      }

      @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  #search-button {
    min-width: 150px;
    height: 75px;
    cursor: pointer;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  #search-button-mobile {
    margin-left: 5px;
    height: 75px;
    cursor: pointer; //margin-left: 0px;
    width: 100%;
    /* max-width: 500px; */
    margin-top: 10px;
    margin-left: 0px;
  }
  .group-search {
    @include media-breakpoint-up(md) {
      margin-bottom: 13px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .search-info {
    color: #fff !important;
    font-size: 17px !important;
    letter-spacing: normal !important;
    margin: 20px;
    line-height: 1.42857 !important;
  }
  .search-block {
    height: auto;
  }
  .btn-40 {
    line-height: 23px;
  }
  .brand {
    max-width: 120px;
  }
  .learn-more {
    margin-right: 30px;
    border: 2px solid #fff !important;
    @include media-breakpoint-down(xs) {
      margin-right: 0px;
      width: 220px;
    }
  }
  .create-your-account {
    border: 2px solid #fff !important;
    @include media-breakpoint-down(xs) {
      display: block;
      width: 220px;
      margin: auto;
      margin-top: 30px;
    }
  }
  .differentes-gammes {
    margin-bottom: -139px;
  }
  .logos-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    img {
      height: 60px;
    }
  }
  .bullet-title {
    font-size: 1.35rem;
    font-weight: 400;
    color: #435f71;
  }
  .answser-button {
    padding-top: 8px;
    border-radius: 25px;
    width: 75px;
    margin-right: 15px;
  }

  .discover-pricing-button {
    padding-top: 7px;
    border-radius: 25px;
    width: 170px;
  }

  .batteries {
    margin: auto;
    max-width: 80%;
    display: block;
    border: 1px solid #fff;
    border-radius: 6px;
    box-shadow: 0 15px 35px 0 rgba(14, 58, 91, 0.2);
    padding: 15px;
    padding-bottom: 25px;
    text-align: center;

    .batteries-title {
      font-size: 16px;
      font-weight: bold;
      display: block;
      text-align: center;
      margin-top: 15px;
    }

    ul {
      text-align: left;
      list-style: none;
    }
  }
}

.subtitle {
  font-size: 1.7rem;
}

.partners-block {
  max-width: 1040px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card--margin-t {
  margin-top: 16px !important;
}

.card--display-flex {
  display: flex !important;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    .card--card {
      margin-top: 2rem !important;
    }
  }
}

.card--justify-around {
  justify-content: space-around !important;
}

.card--step-card {
  box-shadow: 0 0 16px rgba(34, 67, 88, 0.1);
}
.card--card-bg-white {
  background-color: #fff;
}
.card--card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  flex: 0 0 auto;
  text-decoration: none;
  color: #2b4660;
  padding: 16px;
}
.card--text-center {
  text-align: center !important;
}
.card--flex-grow {
  flex: 1 0 0% !important;
}
.card--padding-xl {
  padding: 32px !important;
}
.card--margin {
  margin: 16px !important;
}
.card--card-content {
  min-height: 50%;
}

.card--step-card .card--step-number {
  position: absolute;
  width: 56px;
  height: 56px;
  line-height: 56px;
  top: -28px;
  border-radius: 36px;
  background-color: #f2fafd;
  color: #107aca;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.card--font-700 {
  font-weight: 700 !important;
}
.card--margin {
  margin: 16px !important;
}

.home-brands {
  display: flex;
  justify-content: center;
  align-items: center;
}
