footer.footer {
  background-color: $color-brand-blue-dark;
  padding: ($spacer * 2) 0;
  color: #c3d0da;
  font-size: 14px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
  font-weight: 300;
  p {
    color: #c3d0da;
  }
  a {
    color: #c3d0da !important;
    &:hover,
    &:focus {
      color: white !important;
      text-decoration: none;
    }

    &.social {
        color: #0c3d65 !important;
      &:hover,
      &:focus {
        color: #c3d0da !important;
        text-decoration: none;
      }
    }
  }
  h4,
  h5,
  h6,
  .fake-footer-h4 {
    color: $color-light;
    font-weight: 300;
    font-size: 1.25rem;
  }
  hr {
    border-color: $color-light;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a {
        color: $color-light;
      }
    }
  }
  .social-medias {
    margin-top: 10px;
    a {
      border-radius: 40px;
      background: white;
      color: #0c3d65;
      display: inline-block;
      width: 40px;
      height: 40px;
      i {
        position: relative;
        top: 6px;
      }
      margin-right: 30px;
      margin-left: 30px;
    }
  }
}
