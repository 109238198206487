html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

@media print {
  *, :before, :after, p:first-letter, div:first-letter, blockquote:first-letter, li:first-letter, p:first-line, div:first-line, blockquote:first-line, li:first-line {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
}

body {
  color: #435f71;
  background-color: #fff;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

a {
  color: #0275d8;
  text-decoration: none;
}

a:focus, a:hover {
  color: #014c8c;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

[role="button"] {
  cursor: pointer;
}

a, area, button, [role="button"], input, label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
  background-color: #0000;
}

caption {
  color: #818a91;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: left;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, textarea {
  line-height: inherit;
}

input[type="radio"]:disabled, input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

input[type="search"] {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1rem;
  font-weight: normal;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small, .small {
  font-size: 80%;
  font-weight: normal;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  border-left: .25rem solid #eceeef;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #818a91;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.blockquote-reverse {
  text-align: right;
  border-left: 0;
  border-right: .25rem solid #eceeef;
  padding-left: 0;
  padding-right: 1rem;
}

.blockquote-reverse .blockquote-footer:before {
  content: "";
}

.blockquote-reverse .blockquote-footer:after {
  content: " —";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
  padding: .25rem;
  transition: all .2s ease-in-out;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #818a91;
  font-size: 90%;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

code {
  color: #bd4147;
  background-color: #f7f7f9;
  border-radius: .25rem;
  padding: .2rem .4rem;
  font-size: 90%;
}

a > code {
  color: inherit;
  background-color: inherit;
  padding: 0;
}

kbd {
  color: #fff;
  background-color: #333;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 90%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  color: #435f71;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  background-color: #0000;
  border-radius: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 544px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 992px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 1200px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 544px) {
  .container {
    width: 576px;
    max-width: 100%;
  }
}

@media (width >= 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (width >= 992px) {
  .container {
    width: 940px;
    max-width: 100%;
  }
}

@media (width >= 1200px) {
  .container {
    width: 1170px;
    max-width: 100%;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 544px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 768px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 992px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 1200px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

@media (width >= 544px) {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (width >= 768px) {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (width >= 992px) {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (width >= 1200px) {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 544px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 768px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 992px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (width >= 1200px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.33333%;
}

.pull-2 {
  right: 16.6667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.3333%;
}

.pull-5 {
  right: 41.6667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.3333%;
}

.pull-8 {
  right: 66.6667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.3333%;
}

.pull-11 {
  right: 91.6667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.33333%;
}

.push-2 {
  left: 16.6667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.3333%;
}

.push-5 {
  left: 41.6667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.3333%;
}

.push-8 {
  left: 66.6667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.3333%;
}

.push-11 {
  left: 91.6667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (width >= 544px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.33333%;
  }

  .pull-sm-2 {
    right: 16.6667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.3333%;
  }

  .pull-sm-5 {
    right: 41.6667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.3333%;
  }

  .pull-sm-8 {
    right: 66.6667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.3333%;
  }

  .pull-sm-11 {
    right: 91.6667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.33333%;
  }

  .push-sm-2 {
    left: 16.6667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.3333%;
  }

  .push-sm-5 {
    left: 41.6667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.3333%;
  }

  .push-sm-8 {
    left: 66.6667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.3333%;
  }

  .push-sm-11 {
    left: 91.6667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 768px) {
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-md-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-md-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.33333%;
  }

  .pull-md-2 {
    right: 16.6667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.3333%;
  }

  .pull-md-5 {
    right: 41.6667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.3333%;
  }

  .pull-md-8 {
    right: 66.6667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.3333%;
  }

  .pull-md-11 {
    right: 91.6667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.33333%;
  }

  .push-md-2 {
    left: 16.6667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.3333%;
  }

  .push-md-5 {
    left: 41.6667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.3333%;
  }

  .push-md-8 {
    left: 66.6667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.3333%;
  }

  .push-md-11 {
    left: 91.6667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.33333%;
  }

  .pull-lg-2 {
    right: 16.6667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.3333%;
  }

  .pull-lg-5 {
    right: 41.6667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.3333%;
  }

  .pull-lg-8 {
    right: 66.6667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.3333%;
  }

  .pull-lg-11 {
    right: 91.6667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.33333%;
  }

  .push-lg-2 {
    left: 16.6667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.3333%;
  }

  .push-lg-5 {
    left: 41.6667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.3333%;
  }

  .push-lg-8 {
    left: 66.6667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.3333%;
  }

  .push-lg-11 {
    left: 91.6667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0%;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.33333%;
  }

  .pull-xl-2 {
    right: 16.6667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.3333%;
  }

  .pull-xl-5 {
    right: 41.6667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.3333%;
  }

  .pull-xl-8 {
    right: 66.6667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.3333%;
  }

  .pull-xl-11 {
    right: 91.6667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.33333%;
  }

  .push-xl-2 {
    left: 16.6667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.3333%;
  }

  .push-xl-5 {
    left: 41.6667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.3333%;
  }

  .push-xl-8 {
    left: 66.6667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.3333%;
  }

  .push-xl-11 {
    left: 91.6667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #eceeef;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-sm th, .table-sm td {
  padding: .3rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #eceeef;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover, .table-active, .table-active > th, .table-active > td {
  background-color: #f5f5f5;
}

.table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #e8e8e8;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #435f71;
}

.thead-default th {
  color: #435f71;
  background-color: #eceeef;
}

.table-inverse {
  color: #fff;
  background-color: #435f71;
}

.table-inverse th, .table-inverse td, .table-inverse thead th {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-responsive {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
  display: block;
  overflow-x: auto;
}

.table-responsive.table-bordered {
  border: 0;
}

.form-control {
  color: #435f71;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: .25rem;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #435f71;
  background-color: #fff;
  border-color: #66afe9;
  outline: none;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control::placeholder {
  color: #999;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #eceeef;
}

.form-control:disabled {
  cursor: not-allowed;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #435f71;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  display: block;
}

.col-form-label {
  margin-bottom: 0;
  padding-top: calc(.375rem - 2px);
  padding-bottom: calc(.375rem - 2px);
}

.col-form-label-lg {
  padding-top: calc(.75rem - 2px);
  padding-bottom: calc(.75rem - 2px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.275rem - 2px);
  padding-bottom: calc(.275rem - 2px);
  font-size: .875rem;
}

.col-form-legend {
  margin-bottom: 0;
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 1rem;
}

.form-control-static {
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding-top: .375rem;
  padding-bottom: .375rem;
  line-height: 1.25;
}

.form-control-static.form-control-sm, .input-group-sm > .form-control-static.form-control, .input-group-sm > .form-control-static.input-group-addon, .input-group-sm > .input-group-btn > .form-control-static.btn, .form-control-static.form-control-lg, .input-group-lg > .form-control-static.form-control, .input-group-lg > .form-control-static.input-group-addon, .input-group-lg > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  border-radius: .2rem;
  padding: .275rem .75rem;
  font-size: .875rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .input-group-sm > select.input-group-addon:not([size]):not([multiple]), .input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 1.8625rem;
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  border-radius: .3rem;
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .input-group-lg > select.input-group-addon:not([size]):not([multiple]), .input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 3.16667rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-check {
  margin-bottom: .5rem;
  display: block;
  position: relative;
}

.form-check.disabled .form-check-label {
  color: #818a91;
  cursor: not-allowed;
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 1.25rem;
}

.form-check-input {
  margin-top: .25rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  display: inline-block;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-check-inline + .form-check-inline {
  margin-left: .75rem;
}

.form-control-feedback {
  margin-top: .25rem;
}

.form-control-success, .form-control-warning, .form-control-danger {
  background-position: right .5625rem center;
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  padding-right: 2.25rem;
}

.has-success .form-control-feedback, .has-success .form-control-label, .has-success .col-form-label, .has-success .form-check-label, .has-success .custom-control {
  color: #5cb85c;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-success .input-group-addon {
  color: #5cb85c;
  background-color: #eaf6ea;
  border-color: #5cb85c;
}

.has-success .form-control-success {
  background-image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}

.has-warning .form-control-feedback, .has-warning .form-control-label, .has-warning .col-form-label, .has-warning .form-check-label, .has-warning .custom-control {
  color: #f0ad4e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-warning .input-group-addon {
  color: #f0ad4e;
  background-color: #fff;
  border-color: #f0ad4e;
}

.has-warning .form-control-warning {
  background-image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}

.has-danger .form-control-feedback, .has-danger .form-control-label, .has-danger .col-form-label, .has-danger .form-check-label, .has-danger .custom-control {
  color: #d9534f;
}

.has-danger .form-control {
  border-color: #d9534f;
}

.has-danger .input-group-addon {
  color: #d9534f;
  background-color: #fdf7f7;
  border-color: #d9534f;
}

.has-danger .form-control-danger {
  background-image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (width >= 544px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    vertical-align: middle;
    width: auto;
    display: inline-block;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .form-inline .form-check {
    justify-content: center;
    align-items: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-check-label {
    padding-left: 0;
  }

  .form-inline .form-check-input {
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .custom-control-indicator {
    vertical-align: text-bottom;
    margin-right: .25rem;
    display: inline-block;
    position: static;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem 1rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 2px #0275d840;
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
  opacity: .65;
}

.btn:active, .btn.active {
  background-image: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #9c3;
  border-color: #9c3;
}

.btn-primary:hover {
  color: #fff;
  background-color: #7aa329;
  border-color: #749b27;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px #99cc3380;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #9c3;
  border-color: #9c3;
}

.btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7aa329;
  background-image: none;
  border-color: #749b27;
}

.btn-secondary {
  color: #fff;
  background-color: #328cff;
  border-color: #328cff;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #0070fe;
  border-color: #006bf4;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 2px #328cff80;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #328cff;
  border-color: #328cff;
}

.btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0070fe;
  background-image: none;
  border-color: #006bf4;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 2px #5bc0de80;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #2aabd2;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 2px #5cb85c80;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #419641;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 2px #f0ad4e80;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #eb9316;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 2px #d9534f80;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #c12e2a;
}

.btn-outline-primary {
  color: #9c3;
  background-color: #0000;
  background-image: none;
  border-color: #9c3;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #9c3;
  border-color: #9c3;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px #99cc3380;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #9c3;
  background-color: #0000;
}

.btn-outline-primary:active, .btn-outline-primary.active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #9c3;
  border-color: #9c3;
}

.btn-outline-secondary {
  color: #328cff;
  background-color: #0000;
  background-image: none;
  border-color: #328cff;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #328cff;
  border-color: #328cff;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px #328cff80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #328cff;
  background-color: #0000;
}

.btn-outline-secondary:active, .btn-outline-secondary.active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #328cff;
  border-color: #328cff;
}

.btn-outline-info {
  color: #5bc0de;
  background-color: #0000;
  background-image: none;
  border-color: #5bc0de;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 2px #5bc0de80;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #5bc0de;
  background-color: #0000;
}

.btn-outline-info:active, .btn-outline-info.active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-success {
  color: #5cb85c;
  background-color: #0000;
  background-image: none;
  border-color: #5cb85c;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 2px #5cb85c80;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #5cb85c;
  background-color: #0000;
}

.btn-outline-success:active, .btn-outline-success.active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-warning {
  color: #f0ad4e;
  background-color: #0000;
  background-image: none;
  border-color: #f0ad4e;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 2px #f0ad4e80;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f0ad4e;
  background-color: #0000;
}

.btn-outline-warning:active, .btn-outline-warning.active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-danger {
  color: #d9534f;
  background-color: #0000;
  background-image: none;
  border-color: #d9534f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 2px #d9534f80;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d9534f;
  background-color: #0000;
}

.btn-outline-danger:active, .btn-outline-danger.active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-link {
  color: #0275d8;
  border-radius: 0;
  font-weight: normal;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
  background-color: #0000;
}

.btn-link, .btn-link:focus, .btn-link:active, .btn-link:hover {
  border-color: #0000;
}

.btn-link:focus, .btn-link:hover {
  color: #014c8c;
  background-color: #0000;
  text-decoration: underline;
}

.btn-link:disabled {
  color: #818a91;
}

.btn-link:disabled:focus, .btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3rem;
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .2rem;
  padding: .25rem .75rem;
  font-size: .875rem;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:after {
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-left: .3em solid #0000;
  border-right: .3em solid #0000;
  width: 0;
  height: 0;
  margin-left: .3em;
  display: inline-block;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropup .dropdown-toggle:after {
  border-top: 0;
  border-bottom: .3em solid;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  color: #435f71;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  min-width: 10rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-divider {
  background-color: #e5e5e5;
  height: 1px;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  clear: both;
  color: #435f71;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  width: 100%;
  padding: 3px 1.5rem;
  font-weight: normal;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #3a5261;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #0275d8;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #818a91;
  cursor: not-allowed;
  background-color: #0000;
}

.show > .dropdown-menu {
  display: block;
}

.show > a {
  outline: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  color: #818a91;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-backdrop {
  z-index: 990;
  position: fixed;
  inset: 0;
}

.dropup .dropdown-menu {
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: 0 auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn + .dropdown-toggle-split:after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  display: flex;
  position: relative;
}

.input-group .form-control {
  z-index: 2;
  flex: auto;
  width: 1%;
  margin-bottom: 0;
  position: relative;
}

.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon, .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  color: #435f71;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid #ccc;
  border-radius: .25rem;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
}

.input-group-addon.form-control-sm, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .input-group-addon.btn {
  border-radius: .2rem;
  padding: .275rem .75rem;
  font-size: .875rem;
}

.input-group-addon.form-control-lg, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .input-group-addon.btn {
  border-radius: .3rem;
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child), .input-group-addon:not(:last-child), .input-group-btn:not(:last-child) > .btn, .input-group-btn:not(:last-child) > .btn-group > .btn, .input-group-btn:not(:last-child) > .dropdown-toggle, .input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child), .input-group-addon:not(:first-child), .input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group > .btn, .input-group-btn:not(:first-child) > .dropdown-toggle, .input-group-btn:not(:last-child) > .btn:not(:first-child), .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  white-space: nowrap;
  font-size: 0;
  position: relative;
}

.input-group-btn > .btn {
  flex: 1;
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}

.input-group-btn:not(:last-child) > .btn, .input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}

.input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover, .input-group-btn:not(:first-child) > .btn-group:focus, .input-group-btn:not(:first-child) > .btn-group:active, .input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  cursor: pointer;
  min-height: 1.5rem;
  margin-right: 1rem;
  padding-left: 1.5rem;
  display: inline-flex;
  position: relative;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #0275d8;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #8fcafe;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
  background-color: #eceeef;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #818a91;
  cursor: not-allowed;
}

.custom-control-indicator {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
  top: .25rem;
  left: 0;
}

.custom-checkbox .custom-control-indicator {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #0275d8;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  flex-direction: column;
  display: flex;
}

.custom-controls-stacked .custom-control {
  margin-bottom: .25rem;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  color: #435f71;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ccc;
  border-radius: .25rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.25;
  display: inline-block;
}

.custom-select:focus {
  border-color: #5cb3fd;
  outline: none;
}

.custom-select:focus::-ms-value {
  color: #435f71;
  background-color: #fff;
}

.custom-select:disabled {
  color: #818a91;
  cursor: not-allowed;
  background-color: #eceeef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%;
}

.custom-file {
  cursor: pointer;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  filter: alpha(opacity= 0);
  opacity: 0;
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
}

.custom-file-control {
  z-index: 5;
  color: #435f71;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: .25rem;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-control:lang(en):after {
  content: "Choose file...";
}

.custom-file-control:before {
  z-index: 6;
  color: #435f71;
  background-color: #eceeef;
  border: 1px solid #ccc;
  border-radius: 0 .25rem .25rem 0;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
}

.custom-file-control:lang(en):before {
  content: "Browse";
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5em 1em;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #818a91;
  cursor: not-allowed;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #eceeef #eceeef #ddd;
}

.nav-tabs .nav-link.disabled {
  color: #818a91;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #435f71;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .nav-item.show .nav-link {
  color: #fff;
  cursor: default;
  background-color: #0275d8;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-direction: column;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-text {
  padding-top: .425rem;
  padding-bottom: .425rem;
  display: inline-block;
}

.navbar-toggler {
  background: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  align-self: flex-start;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-toggler-left {
  position: absolute;
  left: 1rem;
}

.navbar-toggler-right {
  position: absolute;
  right: 1rem;
}

@media (width <= 543px) {
  .navbar-toggleable .navbar-nav .dropdown-menu {
    float: none;
    position: static;
  }

  .navbar-toggleable > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 544px) {
  .navbar-toggleable {
    flex-flow: row;
    align-items: center;
  }

  .navbar-toggleable .navbar-nav {
    flex-direction: row;
  }

  .navbar-toggleable .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-toggleable > .container {
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
  }

  .navbar-toggleable .navbar-collapse {
    width: 100%;
    display: flex !important;
  }

  .navbar-toggleable .navbar-toggler {
    display: none;
  }
}

@media (width <= 767px) {
  .navbar-toggleable-sm .navbar-nav .dropdown-menu {
    float: none;
    position: static;
  }

  .navbar-toggleable-sm > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 768px) {
  .navbar-toggleable-sm {
    flex-flow: row;
    align-items: center;
  }

  .navbar-toggleable-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-toggleable-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-toggleable-sm > .container {
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
  }

  .navbar-toggleable-sm .navbar-collapse {
    width: 100%;
    display: flex !important;
  }

  .navbar-toggleable-sm .navbar-toggler {
    display: none;
  }
}

@media (width <= 991px) {
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    float: none;
    position: static;
  }

  .navbar-toggleable-md > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 992px) {
  .navbar-toggleable-md {
    flex-flow: row;
    align-items: center;
  }

  .navbar-toggleable-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-toggleable-md > .container {
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
  }

  .navbar-toggleable-md .navbar-collapse {
    width: 100%;
    display: flex !important;
  }

  .navbar-toggleable-md .navbar-toggler {
    display: none;
  }
}

@media (width <= 1199px) {
  .navbar-toggleable-lg .navbar-nav .dropdown-menu {
    float: none;
    position: static;
  }

  .navbar-toggleable-lg > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 1200px) {
  .navbar-toggleable-lg {
    flex-flow: row;
    align-items: center;
  }

  .navbar-toggleable-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-toggleable-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-toggleable-lg > .container {
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
  }

  .navbar-toggleable-lg .navbar-collapse {
    width: 100%;
    display: flex !important;
  }

  .navbar-toggleable-lg .navbar-toggler {
    display: none;
  }
}

.navbar-toggleable-xl {
  flex-flow: row;
  align-items: center;
}

.navbar-toggleable-xl .navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-toggleable-xl > .container {
  padding-left: 0;
  padding-right: 0;
}

.navbar-toggleable-xl .navbar-nav {
  flex-direction: row;
}

.navbar-toggleable-xl .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-toggleable-xl > .container {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.navbar-toggleable-xl .navbar-collapse {
  width: 100%;
  display: flex !important;
}

.navbar-toggleable-xl .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-toggler, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover, .navbar-light .navbar-toggler:focus, .navbar-light .navbar-toggler:hover {
  color: #000c;
}

.navbar-light .navbar-nav .nav-link {
  color: #0000004d;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #0009;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #00000026;
}

.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
  color: #000c;
}

.navbar-light .navbar-toggler {
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.3)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #0000004d;
}

.navbar-inverse .navbar-brand, .navbar-inverse .navbar-toggler, .navbar-inverse .navbar-brand:focus, .navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-toggler:focus, .navbar-inverse .navbar-toggler:hover {
  color: #fff;
}

.navbar-inverse .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-inverse .navbar-nav .nav-link:focus, .navbar-inverse .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-inverse .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-inverse .navbar-nav .open > .nav-link, .navbar-inverse .navbar-nav .active > .nav-link, .navbar-inverse .navbar-nav .nav-link.open, .navbar-inverse .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-inverse .navbar-toggler {
  border-color: #ffffff1a;
}

.navbar-inverse .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-inverse .navbar-text {
  color: #ffffff80;
}

.card {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card-block {
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: .25rem .25rem 0 0;
}

.card-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 .25rem .25rem;
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-primary {
  background-color: #0275d8;
  border-color: #0275d8;
}

.card-primary .card-header, .card-primary .card-footer {
  background-color: #0000;
}

.card-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.card-success .card-header, .card-success .card-footer {
  background-color: #0000;
}

.card-info {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.card-info .card-header, .card-info .card-footer {
  background-color: #0000;
}

.card-warning {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.card-warning .card-header, .card-warning .card-footer {
  background-color: #0000;
}

.card-danger {
  background-color: #d9534f;
  border-color: #d9534f;
}

.card-danger .card-header, .card-danger .card-footer {
  background-color: #0000;
}

.card-outline-primary {
  background-color: #0000;
  border-color: #9c3;
}

.card-outline-secondary {
  background-color: #0000;
  border-color: #328cff;
}

.card-outline-info {
  background-color: #0000;
  border-color: #5bc0de;
}

.card-outline-success {
  background-color: #0000;
  border-color: #5cb85c;
}

.card-outline-warning {
  background-color: #0000;
  border-color: #f0ad4e;
}

.card-outline-danger {
  background-color: #0000;
  border-color: #d9534f;
}

.card-inverse {
  color: #ffffffa6;
}

.card-inverse .card-header, .card-inverse .card-footer {
  background-color: #0000;
  border-color: #fff3;
}

.card-inverse .card-header, .card-inverse .card-footer, .card-inverse .card-title, .card-inverse .card-blockquote {
  color: #fff;
}

.card-inverse .card-link, .card-inverse .card-text, .card-inverse .card-subtitle, .card-inverse .card-blockquote .blockquote-footer {
  color: #ffffffa6;
}

.card-inverse .card-link:focus, .card-inverse .card-link:hover {
  color: #fff;
}

.card-blockquote {
  border-left: 0;
  margin-bottom: 0;
  padding: 0;
}

.card-img {
  border-radius: .25rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img-top {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-img-bottom {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

@media (width >= 544px) {
  .card-deck {
    flex-flow: wrap;
    display: flex;
  }

  .card-deck .card {
    flex-direction: column;
    flex: 1 0 0;
    display: flex;
  }

  .card-deck .card:not(:first-child) {
    margin-left: 15px;
  }

  .card-deck .card:not(:last-child) {
    margin-right: 15px;
  }

  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group .card {
    flex: 1 0 0;
  }

  .card-group .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group .card:not(:first-child):not(:last-child), .card-group .card:not(:first-child):not(:last-child) .card-img-top, .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }

  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    margin-bottom: .75rem;
    display: inline-block;
  }
}

.breadcrumb {
  background-color: #eceeef;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
}

.breadcrumb:after {
  content: "";
  clear: both;
  display: block;
}

.breadcrumb-item {
  float: left;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #818a91;
  content: "/";
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #818a91;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.page-item.disabled .page-link {
  color: #818a91;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  color: #0275d8;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:focus, .page-link:hover {
  color: #014c8c;
  background-color: #eceeef;
  border-color: #ddd;
  text-decoration: none;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .275rem .75rem;
  font-size: .875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

a.badge:focus, a.badge:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-default {
  background-color: #818a91;
}

.badge-default[href]:focus, .badge-default[href]:hover {
  background-color: #687077;
}

.badge-primary {
  background-color: #0275d8;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  background-color: #025aa5;
}

.badge-success {
  background-color: #5cb85c;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  background-color: #449d44;
}

.badge-info {
  background-color: #5bc0de;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  background-color: #31b0d5;
}

.badge-warning {
  background-color: #f0ad4e;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  background-color: #ec971f;
}

.badge-danger {
  background-color: #d9534f;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  background-color: #c9302c;
}

.jumbotron {
  background-color: #eceeef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (width >= 544px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-hr {
  border-top-color: #d0d5d8;
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: relative;
  top: -.75rem;
  right: -1.25rem;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d0e9c6;
}

.alert-success hr {
  border-top-color: #c1e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bcdff1;
}

.alert-info hr {
  border-top-color: #a6d5ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc;
}

.alert-warning hr {
  border-top-color: #f7ecb5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebcccc;
}

.alert-danger hr {
  border-top-color: #e4b9b9;
}

.alert-danger .alert-link {
  color: #843534;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  text-align: center;
  background-color: #f5f5f5;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  background-color: #0275d8;
  height: 1rem;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  color: #555;
  text-align: inherit;
  width: 100%;
}

.list-group-item-action .list-group-item-heading {
  color: #333;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #555;
  background-color: #f5f5f5;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #435f71;
  background-color: #eceeef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  flex-flow: wrap;
  align-items: center;
  margin-bottom: -1px;
  padding: .75rem 1.25rem;
  display: flex;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-bottom: 0;
}

.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #818a91;
  cursor: not-allowed;
  background-color: #eceeef;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item:disabled .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item:disabled .list-group-item-text {
  color: #818a91;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading > small, .list-group-item.active .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active .list-group-item-text {
  color: #a8d6fe;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:focus, a.list-group-item-success:hover, button.list-group-item-success:focus, button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, button.list-group-item-success.active {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:focus, a.list-group-item-info:hover, button.list-group-item-info:focus, button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, button.list-group-item-info.active {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:focus, a.list-group-item-warning:hover, button.list-group-item-warning:focus, button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, button.list-group-item-warning.active {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:focus, a.list-group-item-danger:hover, button.list-group-item-danger:focus, button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, button.list-group-item-danger.active {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
}

.close:focus, .close:hover {
  color: #000;
  cursor: pointer;
  opacity: .75;
  text-decoration: none;
}

button.close {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow: hidden auto;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  display: flex;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 15px;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e5e5e5;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  display: flex;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (width >= 544px) {
  .modal-dialog {
    max-width: 600px;
    margin: 30px auto;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

.tooltip {
  z-index: 1070;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: break-word;
  opacity: 0;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.tooltip:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.tooltip:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip.show {
  opacity: .9;
}

.tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.tooltip-top .tooltip-inner:before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner:before {
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.tooltip-right, .tooltip.bs-tether-element-attached-left {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.tooltip-right .tooltip-inner:before, .tooltip.bs-tether-element-attached-left .tooltip-inner:before {
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.tooltip-bottom, .tooltip.bs-tether-element-attached-top {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.tooltip-bottom .tooltip-inner:before, .tooltip.bs-tether-element-attached-top .tooltip-inner:before {
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.tooltip.tooltip-left, .tooltip.bs-tether-element-attached-right {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip.tooltip-left .tooltip-inner:before, .tooltip.bs-tether-element-attached-right .tooltip-inner:before {
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  max-width: 200px;
  padding: 3px 8px;
}

.tooltip-inner:before {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.popover {
  z-index: 1060;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  max-width: 276px;
  padding: 1px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.popover:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  text-align: left;
}

.popover:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover.popover-top, .popover.bs-tether-element-attached-bottom {
  margin-top: -10px;
}

.popover.popover-top:before, .popover.popover-top:after, .popover.bs-tether-element-attached-bottom:before, .popover.bs-tether-element-attached-bottom:after {
  border-bottom-width: 0;
  left: 50%;
}

.popover.popover-top:before, .popover.bs-tether-element-attached-bottom:before {
  border-top-color: #00000040;
  margin-left: -11px;
  bottom: -11px;
}

.popover.popover-top:after, .popover.bs-tether-element-attached-bottom:after {
  border-top-color: #fff;
  margin-left: -10px;
  bottom: -10px;
}

.popover.popover-right, .popover.bs-tether-element-attached-left {
  margin-left: 10px;
}

.popover.popover-right:before, .popover.popover-right:after, .popover.bs-tether-element-attached-left:before, .popover.bs-tether-element-attached-left:after {
  border-left-width: 0;
  top: 50%;
}

.popover.popover-right:before, .popover.bs-tether-element-attached-left:before {
  border-right-color: #00000040;
  margin-top: -11px;
  left: -11px;
}

.popover.popover-right:after, .popover.bs-tether-element-attached-left:after {
  border-right-color: #fff;
  margin-top: -10px;
  left: -10px;
}

.popover.popover-bottom, .popover.bs-tether-element-attached-top {
  margin-top: 10px;
}

.popover.popover-bottom:before, .popover.popover-bottom:after, .popover.bs-tether-element-attached-top:before, .popover.bs-tether-element-attached-top:after {
  border-top-width: 0;
  left: 50%;
}

.popover.popover-bottom:before, .popover.bs-tether-element-attached-top:before {
  border-bottom-color: #00000040;
  margin-left: -11px;
  top: -11px;
}

.popover.popover-bottom:after, .popover.bs-tether-element-attached-top:after {
  border-bottom-color: #f7f7f7;
  margin-left: -10px;
  top: -10px;
}

.popover.popover-bottom .popover-title:before, .popover.bs-tether-element-attached-top .popover-title:before {
  content: "";
  border-bottom: 1px solid #f7f7f7;
  width: 20px;
  margin-left: -10px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.popover.popover-left, .popover.bs-tether-element-attached-right {
  margin-left: -10px;
}

.popover.popover-left:before, .popover.popover-left:after, .popover.bs-tether-element-attached-right:before, .popover.bs-tether-element-attached-right:after {
  border-right-width: 0;
  top: 50%;
}

.popover.popover-left:before, .popover.bs-tether-element-attached-right:before {
  border-left-color: #00000040;
  margin-top: -11px;
  right: -11px;
}

.popover.popover-left:after, .popover.bs-tether-element-attached-right:after {
  border-left-color: #fff;
  margin-top: -10px;
  right: -10px;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: 8px 14px;
  font-size: 1rem;
}

.popover-title:empty {
  display: none;
}

.popover-content {
  padding: 9px 14px;
}

.popover:before, .popover:after {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.popover:before {
  content: "";
  border-width: 11px;
}

.popover:after {
  content: "";
  border-width: 10px;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-item {
  width: 100%;
  display: none;
  position: relative;
}

@media (-webkit-transform-3d) {
  .carousel-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out;
  }
}

@supports (transform: translate3d(0, 0, 0)) {
  .carousel-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: flex;
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0;
}

@media (-webkit-transform-3d) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }

  .carousel-item-next, .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }

  .carousel-item-prev, .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

@supports (transform: translate3d(0, 0, 0)) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }

  .carousel-item-next, .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }

  .carousel-item-prev, .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev, .carousel-control-next {
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  width: 15%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-control-prev:focus, .carousel-control-prev:hover, .carousel-control-next:focus, .carousel-control-next:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background: center / 100% 100% no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff80;
  flex: 1 0 auto;
  max-width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
}

.carousel-indicators li:before {
  content: "";
  width: 100%;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 0;
}

.carousel-indicators li:after {
  content: "";
  width: 100%;
  height: 10px;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-faded {
  background-color: #f7f7f7;
}

.bg-primary {
  background-color: #0275d8 !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #025aa5 !important;
}

.bg-success {
  background-color: #5cb85c !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #449d44 !important;
}

.bg-info {
  background-color: #5bc0de !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #31b0d5 !important;
}

.bg-warning {
  background-color: #f0ad4e !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ec971f !important;
}

.bg-danger {
  background-color: #d9534f !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #c9302c !important;
}

.bg-inverse {
  background-color: #435f71 !important;
}

a.bg-inverse:focus, a.bg-inverse:hover {
  background-color: #304451 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-top {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-right {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-left {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (width >= 544px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.flex-first {
  order: -1;
}

.flex-last {
  order: 1;
}

.flex-unordered {
  order: 0;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (width >= 544px) {
  .flex-sm-first {
    order: -1;
  }

  .flex-sm-last {
    order: 1;
  }

  .flex-sm-unordered {
    order: 0;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (width >= 768px) {
  .flex-md-first {
    order: -1;
  }

  .flex-md-last {
    order: 1;
  }

  .flex-md-unordered {
    order: 0;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (width >= 992px) {
  .flex-lg-first {
    order: -1;
  }

  .flex-lg-last {
    order: 1;
  }

  .flex-lg-unordered {
    order: 0;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (width >= 1200px) {
  .flex-xl-first {
    order: -1;
  }

  .flex-xl-last {
    order: 1;
  }

  .flex-xl-unordered {
    order: 0;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (width >= 544px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (width >= 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (width >= 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (width >= 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1030;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-2 {
  margin: 1.5rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.mx-2 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.px-2 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (width >= 544px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-sm-1 {
    margin: 1rem !important;
  }

  .mt-sm-1 {
    margin-top: 1rem !important;
  }

  .mr-sm-1 {
    margin-right: 1rem !important;
  }

  .mb-sm-1 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-1 {
    margin-left: 1rem !important;
  }

  .mx-sm-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .my-sm-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-sm-2 {
    margin: 1.5rem !important;
  }

  .mt-sm-2 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-2 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-2 {
    margin-left: 1.5rem !important;
  }

  .mx-sm-2 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .my-sm-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-sm-3 {
    margin: 3rem !important;
  }

  .mt-sm-3 {
    margin-top: 3rem !important;
  }

  .mr-sm-3 {
    margin-right: 3rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-3 {
    margin-left: 3rem !important;
  }

  .mx-sm-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .my-sm-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-sm-1 {
    padding: 1rem !important;
  }

  .pt-sm-1 {
    padding-top: 1rem !important;
  }

  .pr-sm-1 {
    padding-right: 1rem !important;
  }

  .pb-sm-1 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-1 {
    padding-left: 1rem !important;
  }

  .px-sm-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-sm-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-sm-2 {
    padding: 1.5rem !important;
  }

  .pt-sm-2 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-2 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-2 {
    padding-left: 1.5rem !important;
  }

  .px-sm-2 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-sm-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-sm-3 {
    padding: 3rem !important;
  }

  .pt-sm-3 {
    padding-top: 3rem !important;
  }

  .pr-sm-3 {
    padding-right: 3rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-3 {
    padding-left: 3rem !important;
  }

  .px-sm-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (width >= 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-md-1 {
    margin: 1rem !important;
  }

  .mt-md-1 {
    margin-top: 1rem !important;
  }

  .mr-md-1 {
    margin-right: 1rem !important;
  }

  .mb-md-1 {
    margin-bottom: 1rem !important;
  }

  .ml-md-1 {
    margin-left: 1rem !important;
  }

  .mx-md-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .my-md-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-md-2 {
    margin: 1.5rem !important;
  }

  .mt-md-2 {
    margin-top: 1.5rem !important;
  }

  .mr-md-2 {
    margin-right: 1.5rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-2 {
    margin-left: 1.5rem !important;
  }

  .mx-md-2 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .my-md-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-md-3 {
    margin: 3rem !important;
  }

  .mt-md-3 {
    margin-top: 3rem !important;
  }

  .mr-md-3 {
    margin-right: 3rem !important;
  }

  .mb-md-3 {
    margin-bottom: 3rem !important;
  }

  .ml-md-3 {
    margin-left: 3rem !important;
  }

  .mx-md-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .my-md-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-md-1 {
    padding: 1rem !important;
  }

  .pt-md-1 {
    padding-top: 1rem !important;
  }

  .pr-md-1 {
    padding-right: 1rem !important;
  }

  .pb-md-1 {
    padding-bottom: 1rem !important;
  }

  .pl-md-1 {
    padding-left: 1rem !important;
  }

  .px-md-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-md-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-md-2 {
    padding: 1.5rem !important;
  }

  .pt-md-2 {
    padding-top: 1.5rem !important;
  }

  .pr-md-2 {
    padding-right: 1.5rem !important;
  }

  .pb-md-2 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-2 {
    padding-left: 1.5rem !important;
  }

  .px-md-2 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-md-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-md-3 {
    padding: 3rem !important;
  }

  .pt-md-3 {
    padding-top: 3rem !important;
  }

  .pr-md-3 {
    padding-right: 3rem !important;
  }

  .pb-md-3 {
    padding-bottom: 3rem !important;
  }

  .pl-md-3 {
    padding-left: 3rem !important;
  }

  .px-md-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (width >= 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-lg-1 {
    margin: 1rem !important;
  }

  .mt-lg-1 {
    margin-top: 1rem !important;
  }

  .mr-lg-1 {
    margin-right: 1rem !important;
  }

  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-1 {
    margin-left: 1rem !important;
  }

  .mx-lg-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .my-lg-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-lg-2 {
    margin: 1.5rem !important;
  }

  .mt-lg-2 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-2 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-2 {
    margin-left: 1.5rem !important;
  }

  .mx-lg-2 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .my-lg-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-lg-3 {
    margin: 3rem !important;
  }

  .mt-lg-3 {
    margin-top: 3rem !important;
  }

  .mr-lg-3 {
    margin-right: 3rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-3 {
    margin-left: 3rem !important;
  }

  .mx-lg-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .my-lg-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-lg-1 {
    padding: 1rem !important;
  }

  .pt-lg-1 {
    padding-top: 1rem !important;
  }

  .pr-lg-1 {
    padding-right: 1rem !important;
  }

  .pb-lg-1 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-1 {
    padding-left: 1rem !important;
  }

  .px-lg-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-lg-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-lg-2 {
    padding: 1.5rem !important;
  }

  .pt-lg-2 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-2 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-2 {
    padding-left: 1.5rem !important;
  }

  .px-lg-2 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-lg-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-lg-3 {
    padding: 3rem !important;
  }

  .pt-lg-3 {
    padding-top: 3rem !important;
  }

  .pr-lg-3 {
    padding-right: 3rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-3 {
    padding-left: 3rem !important;
  }

  .px-lg-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (width >= 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-xl-1 {
    margin: 1rem !important;
  }

  .mt-xl-1 {
    margin-top: 1rem !important;
  }

  .mr-xl-1 {
    margin-right: 1rem !important;
  }

  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-1 {
    margin-left: 1rem !important;
  }

  .mx-xl-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .my-xl-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-xl-2 {
    margin: 1.5rem !important;
  }

  .mt-xl-2 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-2 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-2 {
    margin-left: 1.5rem !important;
  }

  .mx-xl-2 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .my-xl-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-xl-3 {
    margin: 3rem !important;
  }

  .mt-xl-3 {
    margin-top: 3rem !important;
  }

  .mr-xl-3 {
    margin-right: 3rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-3 {
    margin-left: 3rem !important;
  }

  .mx-xl-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .my-xl-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-xl-1 {
    padding: 1rem !important;
  }

  .pt-xl-1 {
    padding-top: 1rem !important;
  }

  .pr-xl-1 {
    padding-right: 1rem !important;
  }

  .pb-xl-1 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-1 {
    padding-left: 1rem !important;
  }

  .px-xl-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .py-xl-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-xl-2 {
    padding: 1.5rem !important;
  }

  .pt-xl-2 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-2 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-2 {
    padding-left: 1.5rem !important;
  }

  .px-xl-2 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .py-xl-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-xl-3 {
    padding: 3rem !important;
  }

  .pt-xl-3 {
    padding-top: 3rem !important;
  }

  .pr-xl-3 {
    padding-right: 3rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-3 {
    padding-left: 3rem !important;
  }

  .px-xl-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (width >= 544px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-muted {
  color: #818a91 !important;
}

a.text-muted:focus, a.text-muted:hover {
  color: #687077 !important;
}

.text-primary {
  color: #0275d8 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #025aa5 !important;
}

.text-success {
  color: #5cb85c !important;
}

a.text-success:focus, a.text-success:hover {
  color: #449d44 !important;
}

.text-info {
  color: #5bc0de !important;
}

a.text-info:focus, a.text-info:hover {
  color: #31b0d5 !important;
}

.text-warning {
  color: #f0ad4e !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #ec971f !important;
}

.text-danger {
  color: #d9534f !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #c9302c !important;
}

.text-gray-dark {
  color: #435f71 !important;
}

a.text-gray-dark:focus, a.text-gray-dark:hover {
  color: #304451 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (width <= 543px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (width >= 544px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (width <= 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (width >= 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (width <= 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (width >= 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (width <= 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down, .visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.affix {
  position: fixed;
  top: 1rem;
}

.affix-bottom {
  position: absolute;
}

h1, h2, h3, h4, h5, h6 {
  color: #328cff;
}

p {
  color: #435f71;
  margin: 1.2rem 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.714;
}

@media screen and (width <= 570px) {
  p {
    font-size: 16px;
  }
}

a, .btn-link {
  color: #328cff;
}

.color-gray {
  color: #435f71;
}

.color-blue {
  color: #328cff;
  display: block;
}

.weight-semi-bold {
  font-weight: 500;
}

.fake-h1 {
  color: #435f71;
  margin: 1.2rem 0;
  font-size: 14px;
  line-height: 1.714;
}

@media screen and (width <= 570px) {
  .fake-h1 {
    font-size: 16px;
  }
}

.fake-h2 {
  color: #435f71;
  margin-top: -15px;
  font-size: 14px;
  line-height: 1.714;
}

@media screen and (width <= 570px) {
  .fake-h2 {
    font-size: 16px;
  }
}

.fake-h5 {
  font-size: 1.25rem;
}

.fake-h3 {
  color: #328cff;
  font-size: 1.75rem;
  display: block;
}

.font-larger {
  font-size: 16px;
  font-weight: normal;
}

.font-standard {
  font-size: 14px;
}

@media screen and (width <= 570px) {
  .font-standard {
    font-size: 16px;
  }
}

.font-larger-bold {
  font-size: 16px;
  font-weight: 600;
}

.font-medium-bold {
  font-size: 15px;
  font-weight: bold;
}

.font-medium {
  font-size: 15px;
}

.font-small {
  font-size: 12px;
}

.lead {
  font-size: 15px;
}

@media screen and (width <= 570px) {
  .lead {
    font-size: 16px;
  }
}

.font-bigger {
  font-size: 18px;
}

.carousel {
  height: 100%;
  display: flex;
  position: relative;
}

.carousel-item {
  background-position: top;
  background-size: cover;
  height: 100%;
}

.carousel-item .carousel-caption {
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.carousel-item .carousel-caption h1 {
  color: #fff;
}

@media (width <= 991px) {
  .carousel-item .carousel-caption h1 {
    font-size: 30px;
  }
}

@media (width <= 767px) {
  .carousel-item .carousel-caption h1 {
    font-size: 27px;
  }
}

.carousel-item .container {
  background-position: 100% bottom;
  background-repeat: no-repeat;
  background-size: 24%;
}

@media screen and (width <= 980px) {
  .carousel-item .container {
    background-position: 100% bottom;
  }
}

.carousel-control-prev span, .carousel-control-next span {
  background-color: #9c3;
  background-size: 15px;
  padding: 20px;
}

.carousel-control-next {
  justify-content: flex-end;
  right: 0;
}

.carousel-control-next span {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
}

@media screen and (width <= 570px) {
  .carousel-control-next span {
    font-size: 16px;
  }
}

.carousel-control-prev {
  justify-content: flex-start;
  right: 0;
}

.carousel-control-prev span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.home-hero-subtext {
  margin-bottom: 98px;
}

@media screen and (width <= 576px) {
  .home-hero-subtext {
    width: 80%;
  }
}

@media screen and (width >= 1200px) {
  body.home #address-input {
    width: 470px;
  }
}

@media screen and (width <= 1200px) {
  body.home #address-input {
    width: 320px;
  }
}

@media screen and (width <= 992px) {
  body.home #address-input {
    width: 200px;
  }
}

.container-full {
  width: 100%;
  padding: 0 85px;
}

iframe {
  width: 100%;
  height: auto;
}

hr.clean {
  border-color: #0000 !important;
}

.icone_home {
  border-radius: 100%;
  width: 75px;
  height: 75px;
  margin: auto;
}

.heading--title {
  text-align: center;
  background-color: #328cff;
  margin-bottom: 20px;
  padding: 90px 0;
}

@media (width <= 991px) {
  .heading--title {
    padding: 60px 0;
  }
}

@media (width <= 767px) {
  .heading--title {
    padding: 30px 0;
  }
}

.heading--title h1, .heading--title .lead, .heading--title .lead a {
  color: #fff;
}

section {
  padding: 4rem 0;
}

.hero {
  color: #435f71;
  background-color: #f7f9fd;
  background-position: center;
  background-size: cover;
}

.hero h1, .hero h2, .hero .h2, .hero h3, .hero h4, .hero .lead, .hero p {
  color: #435f71;
}

.title-borderfill {
  padding-bottom: 20px;
  font-weight: 300;
  position: relative;
}

.title-borderfill:after {
  content: "";
  background-color: #9c3;
  width: 100px;
  height: 3px;
  margin-left: -50px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.title-border {
  padding-bottom: 20px;
  font-weight: 300;
  position: relative;
}

.card {
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 1px 1px #00000026, -1px 0 #00000008, 1px 0 #00000008, 0 1px #0000001f;
}

.card--title {
  color: #435f71;
  padding-bottom: 10px;
  font-weight: 300;
  display: inline-block;
  position: relative;
}

.card--title:after {
  content: "";
  border-bottom: 1px solid #f5faff;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card--content {
  min-height: 320px;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.card--small-content {
  min-height: 120px;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.card--photo {
  padding: 5px;
}

.card--photo img {
  width: 100%;
  height: auto;
}

.card--map {
  margin-bottom: 20px;
}

@media (width >= 544px) {
  .card--map {
    min-height: 320px;
  }
}

.card--map_title {
  color: #435f71;
  font-weight: 300;
}

.card--map iframe {
  width: 100%;
  height: 200px;
}

.notif {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
  border-left: 3px solid #00d9d9 !important;
}

@media (width <= 991px) {
  .notif .float-right {
    float: none !important;
  }
}

.fil-ariane p {
  font-size: 14px;
}

@media screen and (width <= 570px) {
  .fil-ariane p {
    font-size: 16px;
  }
}

.fil-ariane a:hover {
  text-decoration: none;
}

.hover-white:hover {
  color: #435f71 !important;
  background: #fff !important;
}

.hover-blue-border:hover {
  color: #435f71 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.scrolled .hover-blue-border:hover {
  color: #328cff !important;
  border-color: #328cff !important;
}

.btn {
  cursor: pointer;
  transition: none;
  box-shadow: none !important;
}

.focus-white:focus {
  color: #fff;
}

.btn-40 {
  height: 40px;
  padding: 8px 16px;
}

.not-bold {
  font-weight: normal;
}

a.no-hover:hover {
  color: #328cff;
  text-decoration: none;
}

a.no-hover:focus {
  text-decoration: none;
}

.default-color, .body-color {
  color: #435f71;
}

.justify-center {
  justify-content: center;
}

.greybg {
  background-color: #f5faff;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.btn-primary:hover {
  background: #99d733;
  border-color: #99d733;
}

.btn-secondary:hover, .btn-secondary:active, .btn-outline-secondary:active, .btn-outline-secondary:hover {
  background: #5aa3ff;
  border-color: #5aa3ff;
}

.btn-outline-secondary:hover, .btn-outline-secondary:active {
  color: #5aa3ff !important;
  background: #fff !important;
}

.btn-secondary:active {
  background: #5aa3ff;
  border-color: #5aa3ff;
}

.btn-primary:focus, .btn-secondary:focus, .btn-danger:focus {
  color: #fff;
}

.table.no-header-first-line tr th {
  border-top: none;
}

.btn-primary:active {
  color: #fff;
  background-color: #9c3;
  border-color: #9c3;
}

.inherit-color {
  color: inherit;
}

.card {
  box-shadow: none;
  border: 0;
  border-radius: 3px;
}

p.reduced-magins {
  margin: 8px 0;
}

.weight-normal {
  font-weight: normal;
}

.weight-light {
  font-weight: 300;
}

.weight-semi-light {
  font-weight: 400;
}

.w-100percent {
  width: 100%;
}

.m-auto {
  margin: auto;
}

.btn {
  font-size: 15px;
}

@media screen and (width <= 570px) {
  .btn {
    font-size: 16px;
  }
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.25rem;
}

.form-control {
  transition: all;
}

.my-account-dropdown-content, .country-dropdown-content {
  box-shadow: 0 6px 13px 2px #b0b0b052;
}

.my-account-dropdown-content .top-triangle, .country-dropdown-content .top-triangle {
  position: absolute;
  top: -11px;
  left: 21px;
}

.my-account-dropdown-content .top-triangle path, .country-dropdown-content .top-triangle path {
  fill: #fff;
  stroke: #e1e9f0;
  stroke-width: 1px;
}

.country-dropdown-content .top-triangle {
  left: 15px;
}

.country-dropdown-content .flag-item {
  margin-right: 7px;
  position: relative;
  top: 3px;
}

.blue-prices {
  color: #328cff;
  font-weight: 600;
}

.blue-prices strong {
  font-weight: 600;
}

.single-search-result-prices {
  margin-top: 11px !important;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.text-ad-links {
  display: none;
}

img {
  opacity: 1;
  transition: opacity .3s;
}

img[data-src]:not([src]) {
  opacity: 0;
}

.dark-blue-button {
  color: #fff;
  background-color: #001d55 !important;
  border: 1px solid #001d55 !important;
}

.dark-blue-button:hover {
  color: #fff !important;
  background-color: #001d55 !important;
  border: 1px solid #001d55 !important;
}

.dark-blue-button.rounded {
  border-radius: 25px;
}

.blue-faded-button {
  color: #1a73e8 !important;
  background-color: #e8f0fe !important;
  border: 1px solid #e8f0fe !important;
}

.blue-faded-button:hover {
  color: #1a73e8 !important;
  background-color: #dbe8ff !important;
  border: 1px solid #dbe8ff !important;
}

.blue-faded-button.rounded {
  border-radius: 25px;
}

.yellow-button {
  color: #435f71;
  border-radius: 5px;
  background-color: #fdc400 !important;
  border: 1px solid #fdc400 !important;
}

.yellow-button:hover, .yellow-button:focus, .yellow-button.active {
  color: #435f71 !important;
  background-color: #fdc400 !important;
  border: 1px solid #fdc400 !important;
}

.yellow-button.rounded, .phone-button.rounded {
  border-radius: 25px;
}

.my-account-button {
  border-width: 2px !important;
  border-radius: 25px !important;
}

input.simple-search-input {
  border: 1px solid #e1e9f0;
}

@media (width <= 543px) {
  input.simple-search-input {
    width: 320px !important;
  }
}

@media (width <= 991px) {
  input.simple-search-input {
    margin-bottom: 10px;
  }
}

.shadow-card {
  border-radius: 4px;
  box-shadow: 0 2px 12px #00000014;
}

.custom-checkbox .custom-control-indicator, .custom-radio .custom-control-indicator {
  background-color: #fff;
  border: 1px solid #aaa;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #0275d8;
  border: none;
}

.btn {
  padding-top: 10px;
  padding-bottom: 10px;
}

.blue-header {
  color: #fff;
  background-color: #328cff;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  box-shadow: 0 4px 6px #435f717a;
}

.blue-header button.close {
  color: #fff;
  opacity: .8;
  position: absolute;
  top: 15px;
  right: 18px;
}

.modal-title {
  font-size: 18px;
  color: #0275d8 !important;
}

.rounded-20 {
  border-radius: 20px;
}

.weight-600 {
  font-weight: 600;
}

.xl-rounded {
  border-radius: 25px;
}

.no-hover:hover {
  text-decoration: none;
}

button.reset {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
}

h3.reset {
  color: inherit;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  font-weight: normal;
  line-height: 24px;
}

.header {
  z-index: 1;
  background-color: #fff;
  align-items: center;
  height: 58px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 10px #435f7133;
}

@media (width <= 991px) {
  .header .container {
    width: 100%;
  }
}

.header .header--inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width <= 991px) {
  .header .header--inner {
    width: 100%;
    display: inline-block;
  }
}

.header .header--inner #logo {
  width: 190px;
  height: auto;
  position: relative;
  top: 2px;
}

@media (width <= 991px) {
  .header .header--inner #logo {
    float: left;
  }
}

.header .header--inner #logo svg {
  width: auto;
  height: auto;
}

.header .header--inner .mainnav {
  background-color: #fff;
  padding-right: 0;
}

@media (width <= 991px) {
  .header .header--inner .mainnav {
    background-color: #0000;
  }

  .header .header--inner .mainnav .navbar-toggler {
    border: 1px solid #435f71;
    margin-top: -18px;
    padding: 5px 10px;
  }

  .header .header--inner .mainnav .navbar-toggler span {
    background-color: #435f71;
    width: 25px;
    height: 2px;
    margin: 6px 0;
    display: block;
  }

  .header .header--inner .mainnav .navbar-collapse {
    text-align: center;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    position: fixed;
    top: 58px;
    left: 0;
  }
}

.header .header--inner .mainnav ul {
  margin: 0;
  padding: 0;
}

@media (width <= 991px) {
  .header .header--inner .mainnav ul {
    align-items: center;
  }
}

.header .header--inner .mainnav ul li {
  list-style: none;
  display: inline-flex;
}

.header .header--inner .mainnav ul li a:not(.dropdown-item) {
  color: #435f71;
  padding: 8px 16px;
  font-size: 15px;
  position: relative;
}

@media screen and (width <= 570px) {
  .header .header--inner .mainnav ul li a:not(.dropdown-item) {
    font-size: 16px;
  }
}

.header .header--inner .mainnav ul li a:not(.dropdown-item).border--btn {
  border: 1px solid #435f71;
  border-radius: 4px;
  margin-left: 16px;
}

.header .header--inner .mainnav ul li a:not(.dropdown-item).border--btn:after {
  display: none;
}

.header .header--inner .mainnav ul li a:not(.dropdown-item):after {
  content: "";
  opacity: 0;
  background-color: #435f71;
  width: calc(100% - 32px);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 16px;
}

.header .header--inner .mainnav ul li a:not(.dropdown-item):not(.my-account-dropdown):hover {
  color: #435f71;
}

.header .header--inner .mainnav ul li a:not(.dropdown-item):not(.my-account-dropdown):hover:not(.flag-link):after {
  opacity: 1;
}

@media (width <= 991px) {
  .header .header--inner .mainnav ul li a:not(.dropdown-item):not(.my-account-dropdown):hover:not(.flag-link):after {
    display: none;
  }
}

.my-account-dropdown {
  cursor: pointer;
}

.my-account-dropdown:hover {
  border: none;
}

.my-account-dropdown-content, .country-dropdown-content {
  margin-top: 15px;
  border: 1px solid #e1e9f0 !important;
}

.my-account-dropdown-content a, .country-dropdown-content a {
  color: #435f71;
  font-size: 15px;
}

@media screen and (width <= 570px) {
  .my-account-dropdown-content a, .country-dropdown-content a {
    font-size: 16px;
  }
}

.my-account-dropdown-content a:hover, .country-dropdown-content a:hover {
  background-color: #009cde;
  color: #fff !important;
}

.my-account-dropdown-content .dropdown-item, .country-dropdown-content .dropdown-item {
  padding: 7px 1rem;
}

.flag-link {
  padding: 0 !important;
}

.flag-link:hover {
  border: none !important;
}

.flag-link svg {
  enable-background: new 0 0 512 512;
  margin: 9px 9px 0;
}

.dropdown-menu {
  border: 1px solid #435f7133;
}

footer.footer {
  color: #c3d0da;
  background-color: #0c3d65;
  padding: 2rem 0;
  font-size: 14px;
  font-weight: 300;
}

@media screen and (width <= 570px) {
  footer.footer {
    font-size: 16px;
  }
}

footer.footer p {
  color: #c3d0da;
}

footer.footer a {
  color: #c3d0da !important;
}

footer.footer a:hover, footer.footer a:focus {
  text-decoration: none;
  color: #fff !important;
}

footer.footer a.social {
  color: #0c3d65 !important;
}

footer.footer a.social:hover, footer.footer a.social:focus {
  text-decoration: none;
  color: #c3d0da !important;
}

footer.footer h4, footer.footer h5, footer.footer h6, footer.footer .fake-footer-h4 {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
}

footer.footer hr {
  border-color: #fff;
}

footer.footer ul {
  margin: 0;
  padding: 0;
}

footer.footer ul li {
  list-style: none;
}

footer.footer ul li a {
  color: #fff;
}

footer.footer .social-medias {
  margin-top: 10px;
}

footer.footer .social-medias a {
  color: #0c3d65;
  background: #fff;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-left: 30px;
  margin-right: 30px;
  display: inline-block;
}

footer.footer .social-medias a i {
  position: relative;
  top: 6px;
}

.block_helper {
  color: #fff;
  text-align: center;
  background-color: #328cff;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 300;
  display: inline-flex;
}

@media (width <= 991px) {
  .block_helper {
    flex-direction: column;
  }
}

.block_helper h3, .block_helper h4, .block_helper h5, .block_helper a, .block_helper .fake-footer-h4 {
  color: #fff;
  padding: 5px;
}

.block_helper h3, .block_helper h4, .block_helper h5, .block_helper .fake-footer-h4 {
  margin-bottom: .5rem;
  font-size: 24px;
  font-weight: 300;
}

.block_helper a {
  font-size: 14px;
}

@media screen and (width <= 570px) {
  .block_helper a {
    font-size: 16px;
  }
}

.pointer {
  cursor: pointer;
}

.no-transitions {
  transition: none;
}

.no-decorations {
  text-decoration: none !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.p-105 {
  padding: 105px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.p-110 {
  padding: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.p-115 {
  padding: 115px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.p-120 {
  padding: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.p-125 {
  padding: 125px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.p-130 {
  padding: 130px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.p-135 {
  padding: 135px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.p-140 {
  padding: 140px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.p-145 {
  padding: 145px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.p-150 {
  padding: 150px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.m-105 {
  margin: 105px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.m-110 {
  margin: 110px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.m-115 {
  margin: 115px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.m-120 {
  margin: 120px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.m-125 {
  margin: 125px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.m-130 {
  margin: 130px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.m-135 {
  margin: 135px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.m-140 {
  margin: 140px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.m-145 {
  margin: 145px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.m-150 {
  margin: 150px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.material-scrolltop {
  z-index: 1000;
  -webkit-tap-highlight-color: #0000;
  -o-transition: all .3s cubic-bezier(.25, .25, 0, 1);
  background: #0275d8;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 0;
  height: 0;
  padding: 0;
  transition: all .3s cubic-bezier(.25, .25, 0, 1);
  display: block;
  position: fixed;
  bottom: 23px;
  right: 23px;
  overflow: hidden;
  box-shadow: 0 3px 10px #00000080;
  outline: none !important;
}

.material-scrolltop:hover {
  cursor: pointer;
  background-color: #0275d8;
  text-decoration: none;
  box-shadow: 0 3px 10px #00000080, 0 3px 15px #00000080;
}

.material-scrolltop:before {
  content: "";
  background: #288ade;
  border-radius: 100%;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.material-scrolltop:active:before {
  -o-transition: all .2s ease-out;
  width: 120%;
  padding-top: 120%;
  transition: all .2s ease-out;
}

.material-scrolltop.reveal {
  width: 56px;
  height: 56px;
}

.material-scrolltop span {
  color: #fff;
  font-size: 25px;
  display: block;
}

.material-scrolltop, .material-scrolltop:before {
  background-image: url("https://www.1001audios.fr/assets/images/top-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.backoffice-nav {
  text-align: center;
  background: #fff;
  margin-top: 3px;
  display: block;
}

.backoffice-nav .nav-item {
  color: #435f71;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 14px;
  line-height: 60px;
  text-decoration: none;
  display: inline-block;
}

.backoffice-nav .nav-item:hover {
  color: #435f71;
  border-bottom: 3px solid #009cde;
}

.backoffice-nav .nav-item.active {
  border-bottom: 3px solid #009cde;
}

.modal-green-check {
  color: #fff;
  text-align: center;
  background-color: #17b355;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-right: 30px;
  font-weight: 700;
  line-height: 28px;
  transition: background-color .3s linear;
  display: inline-block;
}

.audition-title > h2 {
  color: #435f71;
  font-weight: 500;
}

.article-image-legend {
  opacity: .85;
  font-size: 80%;
}

.article-image-container {
  text-align: center;
}

.article-image-container img {
  max-height: 200px;
}

body.home h2, body.home .h2 {
  font-size: 1.7em;
}

body.home .h2 {
  margin-bottom: .5rem;
  font-size: 1.7em;
  line-height: 1.1;
}

body.home .header {
  box-shadow: none;
  background-color: #0000;
  width: 100%;
  transition: background-color .3s, color .3s;
  position: fixed;
  top: 0;
}

body.home .header .header--inner #logo svg {
  width: auto;
  height: auto;
}

body.home .header .header--inner #logo svg .cls-logo-1, body.home .header .header--inner #logo svg .cls-logo-2 {
  fill: #fff;
}

body.home .header .header--inner .mainnav {
  background-color: #0000;
}

@media (width <= 991px) {
  body.home .header .header--inner .mainnav {
    background-color: #0000;
  }

  body.home .header .header--inner .mainnav .navbar-toggler {
    border: 1px solid #fff;
  }

  body.home .header .header--inner .mainnav .navbar-toggler span {
    background-color: #fff;
  }
}

body.home .header .header--inner .mainnav ul li a:not(.dropdown-item) {
  color: #fff;
  transition: background-color .3s, color .3s;
}

@media (width <= 991px) {
  body.home .header .header--inner .mainnav ul li a:not(.dropdown-item) {
    color: #435f71;
  }
}

body.home .header .header--inner .mainnav ul li a:not(.dropdown-item).border--btn {
  border: 1px solid #fff;
}

body.home .header .header--inner .mainnav ul li a:not(.dropdown-item):after {
  background-color: #fff;
}

body.home .header .header--inner .mainnav ul li a:not(.dropdown-item):hover {
  color: #fff;
}

body.home .header.scrolled {
  opacity: .92;
  z-index: 1;
  background-color: #fff;
  align-items: center;
  width: 100%;
  height: 58px;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 10px #435f7133;
}

@media (width <= 991px) {
  body.home .header.scrolled .container {
    width: 100%;
  }
}

body.home .header.scrolled .header--inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width <= 991px) {
  body.home .header.scrolled .header--inner {
    width: 100%;
    display: inline-block;
  }
}

body.home .header.scrolled .header--inner #logo {
  width: 190px;
  height: auto;
  position: relative;
  top: 2px;
}

@media (width <= 991px) {
  body.home .header.scrolled .header--inner #logo {
    float: left;
  }
}

body.home .header.scrolled .header--inner #logo svg {
  width: auto;
  height: auto;
}

body.home .header.scrolled .header--inner #logo svg .cls-logo-2 {
  fill: #328cff;
}

body.home .header.scrolled .header--inner #logo svg .cls-logo-1 {
  fill: #9c3;
}

body.home .header.scrolled .header--inner .mainnav {
  padding-right: 0;
}

@media (width <= 991px) {
  body.home .header.scrolled .header--inner .mainnav {
    background-color: #0000;
  }

  body.home .header.scrolled .header--inner .mainnav .navbar-toggler {
    border: 1px solid #435f71;
    margin-top: -18px;
    padding: 5px 10px;
  }

  body.home .header.scrolled .header--inner .mainnav .navbar-toggler span {
    background-color: #435f71;
    width: 25px;
    height: 2px;
    margin: 6px 0;
    display: block;
  }

  body.home .header.scrolled .header--inner .mainnav .navbar-collapse {
    text-align: center;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    position: fixed;
    top: 58px;
    left: 0;
  }
}

body.home .header.scrolled .header--inner .mainnav ul {
  margin: 0;
  padding: 0;
}

@media (width <= 991px) {
  body.home .header.scrolled .header--inner .mainnav ul {
    align-items: center;
  }
}

body.home .header.scrolled .header--inner .mainnav ul li {
  list-style: none;
  display: inline-flex;
}

body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item) {
  color: #435f71;
  padding: 8px 16px;
  font-size: 15px;
  position: relative;
}

@media screen and (width <= 570px) {
  body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item) {
    font-size: 16px;
  }
}

body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item).border--btn {
  border: 1px solid #435f71;
  border-radius: 4px;
  margin-left: 16px;
}

body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item).border--btn:after {
  display: none;
}

body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item):after {
  content: "";
  opacity: 0;
  background-color: #435f71;
  width: calc(100% - 32px);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 16px;
}

body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item):not(.my-account-dropdown):hover {
  color: #435f71;
}

body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item):not(.my-account-dropdown):hover:not(.flag-link):after {
  opacity: 1;
}

@media (width <= 991px) {
  body.home .header.scrolled .header--inner .mainnav ul li a:not(.dropdown-item):not(.my-account-dropdown):hover:not(.flag-link):after {
    display: none;
  }
}

body.home .home--slider {
  padding: 0;
}

body.home .home--intro {
  text-align: center;
  padding: 30px 0;
}

body.home .home--intro--item {
  margin-top: 25px;
  padding: 0 2.5rem;
}

body.home .home--intro--item p {
  color: #435f71;
  margin-top: 20px;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
}

@media screen and (width <= 570px) {
  body.home .home--intro--item p {
    font-size: 16px;
  }
}

body.home .home--hero {
  padding-bottom: 0;
}

body.home .home--hero ul {
  margin: 0;
  padding-left: 17px;
}

body.home .home--hero ul li {
  padding-top: 5px;
}

@media (width <= 991px) {
  body.home .home--hero .text-right {
    text-align: left !important;
  }
}

body.home .home--evaluate {
  background-color: #fff;
}

body.home .home--info {
  background-color: #fff;
  padding-bottom: 40px;
}

body.home .home--info .separator {
  border-top: 2px solid #eee;
  width: 400px;
  margin: auto;
  position: relative;
  top: 50px;
}

@media (width <= 991px) {
  body.home .home--info .separator {
    width: 100%;
  }
}

body.home .home--gammes {
  margin-bottom: 87px;
}

body.home .home--gammes .card-home {
  border-radius: 6px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 35px #0e3a5b1a;
}

body.home .home--gammes .card-home .card-text {
  background-color: #fff;
  min-height: 140px;
  margin-top: 0;
  padding: 20px;
  position: relative;
  bottom: 0;
}

body.home .home--audioprothésiste {
  text-align: center;
  background-position: 0 40%;
  background-size: cover;
}

body.home .home--audioprothésiste p, body.home .home--audioprothésiste h3, body.home .home--audioprothésiste .fake-h3 {
  color: #fff;
}

body.home .home--audioprothésiste .btn {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
}

body.home #test-auditif .resultat {
  opacity: .5;
}

body.home #test-auditif .resultat.fini {
  opacity: 1;
}

body.home #test-auditif .js-question {
  opacity: .5;
  margin-left: 5px;
  margin-right: 5px;
}

body.home #test-auditif .js-question.current {
  opacity: 1;
}

body.home #test-auditif .pagination {
  text-align: center;
  display: block;
}

body.home .search-block {
  flex-direction: column;
  justify-content: center;
  height: 154px;
  display: flex;
}

body.home .search-header, body.home form.search {
  text-align: center;
}

body.home .carousel-caption {
  z-index: 0;
  text-align: left;
  left: 0;
  position: initial !important;
  margin-top: 150px !important;
  display: block !important;
}

@media (width <= 767px) {
  body.home .carousel-caption {
    bottom: 71px;
  }
}

body.home .carousel-caption h2, body.home .carousel-caption .h2 {
  color: #fff;
  margin-top: 50px;
  font-size: 18px;
}

body.home .carousel-caption, body.home .grayed-area {
  max-width: 700px;
}

body.home .carousel-inner {
  background-color: #f5faff;
}

body.home .search-info {
  color: #fff !important;
  letter-spacing: normal !important;
  margin: 0 !important;
  font-size: 17px !important;
  line-height: 1.42857 !important;
}

body.home #search-button {
  cursor: pointer;
  min-width: 150px;
  height: 50px;
}

body.home .ages {
  color: #435f71;
  justify-content: space-around;
  margin-top: 35px;
  padding-left: 0;
  font-weight: normal;
  list-style: none;
  display: flex;
}

body.home .ages li {
  text-align: center;
  border: 1px solid #fff;
  border-radius: 6px;
  width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 15px 25px;
  display: block;
  box-shadow: 0 15px 35px #0e3a5b33;
}

body.home .ages li .age-title {
  text-align: center;
  min-height: 60px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

@media (width <= 767px) {
  body.home .ages {
    display: block;
  }

  body.home .ages li {
    width: 100%;
    margin-top: 20px;
  }
}

body.home #address-input {
  border-color: #fff;
}

body.home body.home .home--slider {
  height: 100vh;
  padding: 0;
}

body.home .search-input {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

body.home .search-input select, body.home .search-input input {
  flex-grow: 1;
}

body.home .search-input .audiopro-type-selector {
  cursor: pointer;
  border: none;
  border-right: 1px solid #ccc;
  border-radius: 8px 0 0;
  max-width: 170px;
  height: 75px;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 8px !important;
}

@media (width <= 543px) {
  body.home .search-input .audiopro-type-selector {
    display: none;
  }
}

body.home .search-input .input-lg {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 75px;
}

body.home .search-input #locate-me-button {
  color: #435f71;
  background-color: #f5faff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 75px;
  margin-left: -5px;
  font-size: 15px;
}

@media screen and (width <= 570px) {
  body.home .search-input #locate-me-button {
    font-size: 16px;
  }
}

body.home .search-input #locate-me-button.disabled, body.home .search-input #locate-me-button:disabled {
  cursor: default !important;
}

@media (width <= 767px) {
  body.home .search-input #locate-me-button .locate-me-text {
    display: none;
  }
}

@media (width >= 768px) {
  body.home .search-input #locate-me-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

body.home #search-button {
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 150px;
  height: 75px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

body.home #search-button-mobile {
  cursor: pointer;
  width: 100%;
  height: 75px;
  margin-top: 10px;
  margin-left: 0;
}

@media (width >= 768px) {
  body.home .group-search {
    margin-bottom: 13px;
  }
}

@media (width <= 767px) {
  body.home .group-search {
    width: 100%;
    margin-bottom: 10px;
  }
}

body.home .search-info {
  margin: 20px;
  color: #fff !important;
  letter-spacing: normal !important;
  font-size: 17px !important;
  line-height: 1.42857 !important;
}

body.home .search-block {
  height: auto;
}

body.home .btn-40 {
  line-height: 23px;
}

body.home .brand {
  max-width: 120px;
}

body.home .learn-more {
  margin-right: 30px;
  border: 2px solid #fff !important;
}

@media (width <= 543px) {
  body.home .learn-more {
    width: 220px;
    margin-right: 0;
  }
}

body.home .create-your-account {
  border: 2px solid #fff !important;
}

@media (width <= 543px) {
  body.home .create-your-account {
    width: 220px;
    margin: 30px auto auto;
    display: block;
  }
}

body.home .differentes-gammes {
  margin-bottom: -139px;
}

body.home .logos-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

body.home .logos-container img {
  height: 60px;
}

body.home .bullet-title {
  color: #435f71;
  font-size: 1.35rem;
  font-weight: 400;
}

body.home .answser-button {
  border-radius: 25px;
  width: 75px;
  margin-right: 15px;
  padding-top: 8px;
}

body.home .discover-pricing-button {
  border-radius: 25px;
  width: 170px;
  padding-top: 7px;
}

body.home .batteries {
  text-align: center;
  border: 1px solid #fff;
  border-radius: 6px;
  max-width: 80%;
  margin: auto;
  padding: 15px 15px 25px;
  display: block;
  box-shadow: 0 15px 35px #0e3a5b33;
}

body.home .batteries .batteries-title {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

body.home .batteries ul {
  text-align: left;
  list-style: none;
}

.partners-block {
  justify-content: space-between;
  align-items: center;
  max-width: 1040px;
  margin: 50px auto auto;
  display: flex;
}

.card--margin-t {
  margin-top: 16px !important;
}

.card--display-flex {
  display: flex !important;
}

@media (width <= 767px) {
  .card--display-flex {
    flex-direction: column;
  }

  .card--display-flex .card--card {
    margin-top: 2rem !important;
  }
}

.card--justify-around {
  justify-content: space-around !important;
}

.card--step-card {
  box-shadow: 0 0 16px #2243581a;
}

.card--card-bg-white {
  background-color: #fff;
}

.card--card {
  color: #2b4660;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  padding: 16px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.card--text-center {
  text-align: center !important;
}

.card--flex-grow {
  flex: 1 0 !important;
}

.card--padding-xl {
  padding: 32px !important;
}

.card--card-content {
  min-height: 50%;
}

.card--step-card .card--step-number {
  color: #107aca;
  text-align: center;
  background-color: #f2fafd;
  border-radius: 36px;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
  line-height: 56px;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
}

.card--font-700 {
  font-weight: 700 !important;
}

.card--margin {
  margin: 16px !important;
}

.home-brands {
  justify-content: center;
  align-items: center;
  display: flex;
}

body.pricing .logo-container {
  margin-top: 14px;
  position: relative;
}

body.pricing .logo {
  width: 200px;
}

body.pricing .logo .cls-logo-1, body.pricing .logo .cls-logo-2 {
  fill: #fff;
}

body.pricing .call-us {
  color: #fff;
  font-weight: 300;
  position: absolute;
  top: 0;
  right: 0;
}

body.pricing .call-us strong {
  font-weight: 400;
}

body.pricing .screens {
  z-index: 1000;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80vw;
  height: 55vh;
  max-height: 340px;
  margin: auto auto auto 10vw;
  position: absolute;
  bottom: 0;
}

body.pricing h2 {
  font-size: 1.7em;
  font-weight: 300;
}

body.pricing .carousel-item {
  background-position: center;
  background-size: cover;
  height: 100%;
}

body.pricing .carousel-item .carousel-caption {
  justify-content: center;
  align-items: center;
  height: 170px;
  top: 200px;
  display: flex !important;
}

body.pricing .carousel-item .carousel-caption .title-container {
  margin-top: -150px;
}

body.pricing .carousel-item .carousel-caption h1 {
  color: #fff;
}

@media (width <= 991px) {
  body.pricing .carousel-item .carousel-caption h1 {
    font-size: 30px;
  }
}

@media (width <= 767px) {
  body.pricing .carousel-item .carousel-caption h1 {
    font-size: 27px;
  }
}

body.pricing .carousel-item .carousel-caption h2 {
  color: #fff;
}

@media (width <= 991px) {
  body.pricing .carousel-item .carousel-caption h2 {
    font-size: 25px;
  }
}

@media (width <= 767px) {
  body.pricing .carousel-item .carousel-caption h2 {
    font-size: 22px;
  }
}

body.pricing .carousel-item .container {
  height: 90vh;
  min-height: 420px;
}

body.pricing .home--slider {
  height: 90vh;
  min-height: 390px;
  padding: 0;
}

body.pricing .home--intro {
  text-align: center;
  padding: 30px 0;
}

body.pricing .home--intro--item {
  margin-top: 25px;
  padding: 0 2.5rem;
}

body.pricing .home--intro--item img {
  max-width: 42px;
}

body.pricing .home--intro--item p {
  color: #435f71;
  margin-top: 20px;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
}

@media screen and (width <= 570px) {
  body.pricing .home--intro--item p {
    font-size: 16px;
  }
}

body.pricing .home--intro h3 {
  color: #435f71;
  font-size: 1.35rem;
  font-weight: 400;
}

body.pricing .compte-pro {
  background-color: #f5faff;
}

body.pricing .compte-pro h2 {
  color: #435f71;
  font-weight: 400;
}

body.pricing .compte-pro img {
  max-width: 100%;
  box-shadow: 0 12px 15px #0000003d, 0 17px 50px #00000030;
}

body.pricing .compte-pro p {
  font-size: 16px;
}

body.pricing .compte-pro ul {
  padding: 0;
  list-style-type: none;
}

body.pricing .compte-pro ul li {
  font-size: 16px;
}

body.pricing .trust img {
  max-height: 120px;
  margin-left: 10px;
  margin-right: 33px;
}

@media (width <= 991px) {
  body.pricing .trust img {
    margin-right: 10px;
  }
}

body.pricing .faq {
  background-color: #f5faff;
}

body.pricing .faq h5 {
  color: #435f71;
  font-weight: 400;
}

body.pricing .logos-container {
  align-items: center;
  min-height: 200px;
}

@media (width >= 992px) {
  body.pricing .logos-container {
    justify-content: center;
    display: flex;
  }
}

@media (width <= 991px) {
  body.pricing .logos-container {
    text-align: center;
  }

  body.pricing .logos-container img {
    margin-top: 20px;
  }
}

body.pricing .create-account-button {
  color: #435f71;
  border: 1px solid #435f71;
  padding: 8px 16px;
  font-size: 15px;
  transition: background-color .3s, color .3s;
  position: relative;
  border-width: 2px !important;
  border-radius: 25px !important;
}

body.pricing .create-account-button:hover {
  color: #fff;
  background-color: #435f71;
  text-decoration: none;
}

body.pricing .gray-link {
  color: #435f71;
}

.subtitle {
  font-size: 1.7rem;
}

.fiche--inner {
  background-color: #f5faff;
  padding-top: 0;
  padding-bottom: 20px;
}

.fiche--inner .fiche-title {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 400;
}

.fiche--inner .center-address {
  color: #435f71;
  font-size: 14px;
  font-weight: normal;
  line-height: 23px;
}

.fiche--inner .enseigne {
  color: #023866;
  font-size: 22px;
}

.fiche--inner .card--content {
  min-height: 10px;
}

.fiche--inner .card--content .btn {
  margin-bottom: 1px;
}

.fiche--inner p.normal-color {
  color: inherit;
  font-weight: normal;
}

.fiche--inner .icone--title {
  color: #023866;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-left: 40px;
  font-size: 20px;
}

.fiche--inner .icone--title_horraire {
  background-image: url("https://1001audios.fr/images/icone_horraire.svg");
}

.fiche--inner .icone--title_img {
  background-image: url("https://1001audios.fr/images/icone_img.svg");
}

.fiche--inner .icone--title_info {
  background-image: url("https://1001audios.fr/images/icone_info.svg");
}

.fiche--inner .icone--title_localisation {
  background-image: url("https://1001audios.fr/images/icone_localisation.svg");
}

.fiche--inner .icone--title_mail {
  background-image: url("https://1001audios.fr/images/icone_mail.svg");
}

.fiche--inner .icone--title_marque {
  background-image: url("https://1001audios.fr/images/icone_marque.svg");
}

.fiche--inner .icone--title_mut {
  background-image: url("https://1001audios.fr/images/icone_mut.svg");
}

.fiche--inner .icone--title_offre {
  background-image: url("https://1001audios.fr/images/icone_offre.svg");
}

.fiche--inner .icone--title_pay {
  background-image: url("https://1001audios.fr/images/icone_pay.svg");
}

.fiche--inner .icone--title_user {
  background-image: url("https://1001audios.fr/images/icone_user.svg");
}

.fiche--inner .icone--title_web {
  background-image: url("https://1001audios.fr/images/icone_web.svg");
}

.fiche--inner .icone--title_orl {
  background-image: url("https://1001audios.fr/images/icone_orl.png");
}

.fiche--inner .logo {
  line-height: 130px;
}

@media (width <= 767px) {
  .fiche--inner .logo {
    text-align: center;
  }
}

.fiche--inner .logo img {
  height: auto;
}

@media (width <= 767px) {
  .fiche--inner .logo img {
    max-width: 100%;
  }
}

@media (width >= 768px) {
  .fiche--inner .logo img {
    width: 100%;
  }
}

.fiche--inner .small-table {
  font-size: 14px;
}

.fiche--inner .small-table th, .fiche--inner table.no-first-line tr:first-child td {
  border-top: none;
}

.fiche--inner .small-logo {
  max-width: 120px;
  margin-bottom: 20px;
  margin-left: 21px;
  margin-right: 21px;
}

.fiche--inner .payment-item, .fiche--inner .service-item {
  font-size: 14px;
  line-height: 1.714;
}

.fiche--inner .payment-title {
  margin-left: 17px;
  display: inline-block;
}

.fiche--inner table.no-interlines td {
  border-top: none;
}

.fiche--inner .main-infos p {
  margin: 5px;
}

.fiche--inner .rdv-mobile-button-container {
  display: none;
}

@media (width <= 767px) {
  .fiche--inner .rdv-mobile-button-container {
    text-align: center;
    z-index: 500;
    height: 34px;
    display: block;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
  }
}

.fiche--inner .fiche-buttons-container {
  justify-content: space-between;
  align-items: center;
  min-height: 46px;
  line-height: 34px;
  display: flex;
}

.fiche--inner .fiche-buttons-container .phone-button {
  border-radius: 20px;
  min-width: 145px;
  margin-right: 5px;
  display: inline-block;
}

.fiche--inner .fiche-buttons-container .telephone {
  text-align: left;
  min-width: 170px;
  height: 32px;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-right: 10px;
  line-height: 30px;
  position: relative;
  top: 2px;
}

.fiche--inner .block-map {
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: -webkit-sticky;
  position: sticky;
  top: 96px;
}

.fiche--inner .selected-day {
  color: #0c0;
}

.fiche--inner .personalized-offer {
  color: #ff0030;
  margin-top: -6px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.fiche--inner .valid-until {
  font-size: 14px;
}

.fiche--inner .step-container {
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

.fiche--inner .green-check {
  color: #fff;
  text-align: center;
  background-color: #17b355;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-weight: 700;
  line-height: 28px;
  transition: background-color .3s linear;
  position: absolute;
  top: 0;
  left: -14px;
}

.fiche--inner .pricing-text {
  color: #435f71;
  font-size: 16px;
  font-weight: 600;
}

.fiche--inner .pricing-card {
  border-left: 3px solid #00d9d9;
}

.fiche--inner .footer-text {
  font-size: 14px;
}

.fiche--inner .card {
  border-radius: 8px;
  box-shadow: 0 2px 12px #00000014;
}

#rdvModal label {
  font-size: 14px;
}

#rdvModal .modal-title {
  margin: auto;
}

#rdvModal .modal-dialog {
  max-width: 450px;
}

#rdvModal .modal-content {
  background-color: #f5faff;
}

#rdvModal .modal-body {
  padding-left: 40px;
  padding-right: 40px;
}

#rdvModal .modal-footer {
  text-align: center;
  display: block;
}

hr.white {
  border-top: 1px solid #fff;
}

.list-orls .single-orl .orl-title {
  color: #328cff;
  margin-bottom: 5px;
  font-size: 15px;
}

.list-orls .single-orl .orl-address, .list-orls .single-orl .orl-city, .list-orls .single-orl .orl-phone {
  font-size: 14px;
}

.rdv-block {
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px #435f7114;
}

.rdv-block .rdv-header {
  color: #fff;
  background-color: #328cff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 30px;
  padding: 10px;
  font-size: 16px;
  box-shadow: 0 4px 6px #435f7140;
}

.rdv-block .rdv-header strong {
  font-size: 18px;
  font-weight: 500;
}

.rdv-block .rdv-description {
  background-color: #fff;
  border-radius: 8px;
}

.rdv-block .rdv-description input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  width: 80%;
  margin: 10px auto 18px;
  padding: 5px;
  display: block;
}

.rdv-block .rdv-description input:focus {
  border-bottom: 2px solid #328cff;
  margin-bottom: 17px;
}

.rdv-block .rdv-description .rdv-text {
  margin-bottom: 20px;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.rdv-block .rdv-description .rdv-text strong {
  font-weight: 500;
}

.rdv-block .rdv-description a:not(.data-protection) {
  margin-top: 10px;
  margin-bottom: 20px;
}

.rdv-block .rdv-description button {
  background-color: #328cff;
  margin-top: 15px;
  margin-bottom: 21px;
}

.rdv-block .rdv-description .alert {
  margin: 15px 15px 25px;
}

.rdv-block .custom-control-description {
  position: relative;
  top: 2px;
}

.rdv-block label.custom-control {
  margin-bottom: 4px;
}

.phoneNumberModal .modal-content {
  border: 1px solid #fff;
  margin-top: 118px;
}

.phoneNumberModal .addresse {
  font-size: 14px;
}

.phoneNumberModal .p-absolute {
  position: absolute;
}

.phoneNumberModal .modal-free-call {
  color: #777;
}

.center-topbar {
  z-index: 110;
  opacity: 0;
  background: #fff;
  width: 100%;
  padding: 12px 0;
  transition: transform .2s ease-in-out, opacity .2s, -webkit-transform .2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
  box-shadow: 0 1px 2px #ccc;
}

.center-topbar.visible {
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  transform: translateZ(0);
  box-shadow: 0 2px 10px #435f7133;
}

@media (width >= 992px) {
  .center-topbar.visible {
    opacity: 1;
  }
}

.center-topbar .image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.center-topbar .topbar-image {
  max-width: 100%;
  max-height: 55px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%)translateX(-50%);
}

.center-topbar .center-address-header {
  font-size: 15px;
  line-height: 18px;
}

.center-topbar .center-address-content {
  font-size: 14px;
  line-height: 18px;
}

.simple-rounded-search {
  width: 80%;
  min-width: 300px;
  height: 40px;
  margin: auto;
  position: relative;
}

.simple-rounded-search input {
  border: none;
  border-radius: 25px;
  height: 40px;
  padding-left: 24px;
  box-shadow: 0 2px 12px #0000001a;
  width: 100% !important;
}

.simple-rounded-search input:focus {
  border: none;
  box-shadow: 0 0 17px #0000004d;
}

.simple-rounded-search button {
  color: #328cff;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 11px;
  right: 15px;
}

.simple-rounded-search button:hover, .simple-rounded-search button:focus, .simple-rounded-search button:active {
  color: #328cff;
  background: none;
  border: none;
}

.non-premium-ad {
  position: -webkit-sticky;
  position: sticky;
  top: 102px;
}

.non-premium-ad.positioned-top {
  top: 25px;
}

.news-title {
  color: #435f71;
  font-size: 16px;
}

.news-text {
  font-size: 14px;
}

.gender-selector {
  color: #999;
  text-align: left;
  width: 80%;
  margin: auto;
  padding-left: 5px;
  font-size: 1rem;
}

.modal-rdv-form {
  max-width: 330px;
  margin: 20px auto auto;
}

.modal-rdv-form input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  width: 80%;
  margin: 10px auto 18px;
  padding: 5px;
  display: block;
}

.prevent-p-margin p {
  margin-top: 0;
}

.fiche-opening .currently-open, .desktop-fiche-opening .currently-open, .mobile-fiche-opening .currently-open {
  color: #0c0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.fiche-opening .currently-closed, .desktop-fiche-opening .currently-closed, .mobile-fiche-opening .currently-closed {
  color: #f22111;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.desktop-fiche-opening {
  text-align: right;
}

@media (width <= 767px) {
  .desktop-fiche-opening {
    display: none;
  }
}

.mobile-fiche-opening {
  margin-top: 20px;
}

@media (width >= 768px) {
  .mobile-fiche-opening {
    display: none;
  }
}

.single-review {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.single-review .review-title {
  font-size: 13px;
  font-weight: 600;
}

.single-review .review-stars {
  margin-top: 0;
  margin-bottom: 8px;
}

.single-review .review-content, .single-review .review-content-full {
  font-size: 14px;
}

.single-review .review-content-full {
  display: none;
}

.single-review .expander {
  font-size: 10px;
}

.review-carousel {
  min-height: 142px;
}

@media (width <= 991px) {
  .reviews-desktop {
    display: none;
  }
}

@media (width >= 992px) {
  .reviews-mobile {
    display: none;
  }
}

.blue-card {
  background: #e0f1fd;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 35px;
  margin-right: 35px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.blue-card > p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.blue-card-appendix {
  padding-top: 5px;
  padding-left: 35px;
  padding-right: 35px;
  line-height: 13px;
}

.blue-card-appendix > p {
  margin: 0;
  padding: 0;
  font-size: 80%;
  font-weight: 400;
  line-height: 13px;
}

body.search, body.my-centers, body.registration {
  background-color: #f5faff;
}

body.search .search--form, body.my-centers .search--form, body.registration .search--form {
  color: #fff;
  background-color: #328cff;
  background-position: center;
  background-size: cover;
  padding: 20px;
}

@media (width <= 991px) {
  body.search .search--form, body.my-centers .search--form, body.registration .search--form {
    text-align: center;
  }
}

@media (width <= 767px) {
  body.search .search--form, body.my-centers .search--form, body.registration .search--form {
    padding-left: 0;
    padding-right: 0;
  }
}

body.search .search--form--inner, body.my-centers .search--form--inner, body.registration .search--form--inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width <= 991px) {
  body.search .search--form--inner, body.my-centers .search--form--inner, body.registration .search--form--inner {
    flex-direction: column-reverse;
  }
}

@media (width >= 1200px) {
  body.search .search--form .search-explanatory-text, body.my-centers .search--form .search-explanatory-text, body.registration .search--form .search-explanatory-text {
    text-align: center;
    width: calc(100% - 700px);
  }
}

@media (width <= 767px) {
  body.search .search--form form .form-group, body.my-centers .search--form form .form-group, body.registration .search--form form .form-group {
    width: 100%;
    margin-bottom: 10px;
  }
}

body.search .search--form form input, body.my-centers .search--form form input, body.registration .search--form form input {
  border-radius: 0;
  width: 190px;
  height: 62.5px;
  margin-right: 5px;
  padding: 15px;
}

@media (width >= 1200px) {
  body.search .search--form form input, body.my-centers .search--form form input, body.registration .search--form form input {
    width: 335px;
  }
}

@media (width <= 991px) {
  body.search .search--form form input, body.my-centers .search--form form input, body.registration .search--form form input {
    text-align: left;
    display: inline-block;
  }
}

@media (width <= 767px) {
  body.search .search--form form input, body.my-centers .search--form form input, body.registration .search--form form input {
    width: calc(100% - 204px);
    margin: 0;
    display: inline-block;
  }
}

@media (width <= 543px) {
  body.search .search--form form input, body.my-centers .search--form form input, body.registration .search--form form input {
    vertical-align: middle;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    width: calc(100% - 40px);
    display: inline-block;
  }
}

body.search .search--form form .audiopro-type-selector, body.my-centers .search--form form .audiopro-type-selector, body.registration .search--form form .audiopro-type-selector {
  cursor: pointer;
  border: none;
  border-right: 1px solid #ccc;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 170px;
  height: 62.5px;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

@media (width <= 543px) {
  body.search .search--form form .audiopro-type-selector, body.my-centers .search--form form .audiopro-type-selector, body.registration .search--form form .audiopro-type-selector {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    display: block;
  }
}

body.search .search--form form #locate-me-button, body.my-centers .search--form form #locate-me-button, body.registration .search--form form #locate-me-button {
  color: #435f71;
  background-color: #f5faff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 40px;
  height: 62.5px;
  margin-left: -7px;
  padding: 10px;
  font-size: 15px;
}

@media screen and (width <= 570px) {
  body.search .search--form form #locate-me-button, body.my-centers .search--form form #locate-me-button, body.registration .search--form form #locate-me-button {
    font-size: 16px;
  }
}

@media (width >= 768px) {
  body.search .search--form form #locate-me-button, body.my-centers .search--form form #locate-me-button, body.registration .search--form form #locate-me-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

body.search .search--form form #locate-me-button.disabled, body.search .search--form form #locate-me-button:disabled, body.my-centers .search--form form #locate-me-button.disabled, body.my-centers .search--form form #locate-me-button:disabled, body.registration .search--form form #locate-me-button.disabled, body.registration .search--form form #locate-me-button:disabled {
  cursor: default !important;
}

body.search .search--form form #search-button, body.my-centers .search--form form #search-button, body.registration .search--form form #search-button {
  height: 62.5px;
  font-size: 18px;
}

@media (width >= 768px) {
  body.search .search--form form #search-button, body.my-centers .search--form form #search-button, body.registration .search--form form #search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}

@media (width <= 767px) {
  body.search .search--form form #search-button, body.my-centers .search--form form #search-button, body.registration .search--form form #search-button {
    margin-left: 0;
  }
}

body.search .search--form form .btn, body.my-centers .search--form form .btn, body.registration .search--form form .btn {
  padding: 15px 30px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

@media (width <= 767px) {
  body.search .search--form form .btn, body.my-centers .search--form form .btn, body.registration .search--form form .btn {
    width: 100%;
  }
}

body.search .search--form h3, body.search .search--form .fake-h3, body.search .search--form .search-explanatory-text, body.my-centers .search--form h3, body.my-centers .search--form .fake-h3, body.my-centers .search--form .search-explanatory-text, body.registration .search--form h3, body.registration .search--form .fake-h3, body.registration .search--form .search-explanatory-text {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 300;
}

@media screen and (width <= 570px) {
  body.search .search--form h3, body.search .search--form .fake-h3, body.search .search--form .search-explanatory-text, body.my-centers .search--form h3, body.my-centers .search--form .fake-h3, body.my-centers .search--form .search-explanatory-text, body.registration .search--form h3, body.registration .search--form .fake-h3, body.registration .search--form .search-explanatory-text {
    font-size: 16px;
  }
}

@media (width <= 991px) {
  body.search .search--form h3, body.search .search--form .fake-h3, body.search .search--form .search-explanatory-text, body.my-centers .search--form h3, body.my-centers .search--form .fake-h3, body.my-centers .search--form .search-explanatory-text, body.registration .search--form h3, body.registration .search--form .fake-h3, body.registration .search--form .search-explanatory-text {
    margin-bottom: 20px;
  }
}

body.search .search--inner, body.my-centers .search--inner, body.registration .search--inner {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 20px;
}

body.search .search--inner .search--map, body.my-centers .search--inner .search--map, body.registration .search--inner .search--map {
  width: 100%;
}

body.search .search--inner .search--map .search--map--inner, body.my-centers .search--inner .search--map .search--map--inner, body.registration .search--inner .search--map .search--map--inner {
  width: 100%;
  height: calc(100vh - 176px);
  padding-top: 20px;
}

body.search .search--inner .search--map .search--map--inner iframe, body.my-centers .search--inner .search--map .search--map--inner iframe, body.registration .search--inner .search--map .search--map--inner iframe {
  height: 100%;
}

body.search .image-link, body.my-centers .image-link, body.registration .image-link {
  line-height: 150px;
}

body.search #map, body.my-centers #map, body.registration #map {
  border-radius: 8px;
  height: 500px;
  margin-top: 28px;
  position: relative;
  overflow: hidden;
}

body.search #map-container, body.search .sticky-wrapper, body.my-centers #map-container, body.my-centers .sticky-wrapper, body.registration #map-container, body.registration .sticky-wrapper {
  z-index: 10;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 19px;
}

body.search .z0, body.my-centers .z0, body.registration .z0 {
  z-index: 0;
}

body.search .refine-search-block, body.my-centers .refine-search-block, body.registration .refine-search-block {
  margin-top: 20px;
}

body.search .refine-search-block a, body.my-centers .refine-search-block a, body.registration .refine-search-block a {
  color: #435f71;
  margin: 1.2rem 0;
  font-size: 14px;
  line-height: 1.714;
}

@media screen and (width <= 570px) {
  body.search .refine-search-block a, body.my-centers .refine-search-block a, body.registration .refine-search-block a {
    font-size: 16px;
  }
}

body.search .disclaimer, body.my-centers .disclaimer, body.registration .disclaimer, body.search .disclaimer p, body.my-centers .disclaimer p, body.registration .disclaimer p {
  margin-bottom: 0;
}

body.search #address-input, body.my-centers #address-input, body.registration #address-input {
  border-color: #fff;
}

body.search .pager, body.my-centers .pager, body.registration .pager {
  text-align: center;
  margin-top: 10px;
}

@media screen and (width <= 570px) {
  body.search .pager .pager-item, body.my-centers .pager .pager-item, body.registration .pager .pager-item {
    font-size: 24px;
  }
}

body.search .pager a, body.my-centers .pager a, body.registration .pager a {
  margin: 5px;
}

body.search .pager a.current, body.my-centers .pager a.current, body.registration .pager a.current {
  color: #435f71;
  display: inline-block;
  position: relative;
}

body.search .pager a.current:after, body.my-centers .pager a.current:after, body.registration .pager a.current:after {
  content: "";
  background-color: #515151;
  border-radius: 5px;
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  top: 24px;
}

@media screen and (width <= 570px) {
  body.search .pager a.current:after, body.my-centers .pager a.current:after, body.registration .pager a.current:after {
    top: 33px;
  }
}

body.search .pager a.current:not(:first-child):after, body.my-centers .pager a.current:not(:first-child):after, body.registration .pager a.current:not(:first-child):after {
  left: 2px;
}

@media screen and (width <= 570px) {
  body.search .pager a.current:not(:first-child):after, body.my-centers .pager a.current:not(:first-child):after, body.registration .pager a.current:not(:first-child):after {
    left: 5px;
  }
}

body.search .pager a.previous-results, body.my-centers .pager a.previous-results, body.registration .pager a.previous-results {
  float: left;
}

body.search .pager a.previous-results.disabled, body.my-centers .pager a.previous-results.disabled, body.registration .pager a.previous-results.disabled {
  color: #328cff;
  opacity: .4;
}

body.search .pager a.next-results, body.my-centers .pager a.next-results, body.registration .pager a.next-results {
  float: right;
}

body.search .pager a.next-results.disabled, body.my-centers .pager a.next-results.disabled, body.registration .pager a.next-results.disabled {
  color: #328cff;
  opacity: .4;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.sticky-wrapper {
  z-index: 0;
}

.single-search-result {
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 2px 12px #00000014;
}

.single-search-result.active {
  border: 1px solid #328cff;
  box-shadow: 0 0 17px #0000004d;
}

.single-search-result:hover {
  border: 1px solid #fff;
  box-shadow: 0 0 17px #0000004d;
}

@media (width >= 768px) {
  .single-search-result:last-child {
    margin-bottom: 0;
  }
}

.single-search-result .telephone {
  text-align: center;
  height: 32px;
  line-height: 30px;
  position: relative;
  top: 2px;
}

.single-search-result.vedette {
  border: 1px solid #0596de;
}

@media (width <= 767px) {
  .single-search-result {
    padding-left: 35px;
    padding-right: 35px;
  }

  .single-search-result .horraire {
    display: block !important;
  }

  .single-search-result .simple-line-description {
    margin-top: -7px;
    margin-bottom: 15px !important;
  }
}

.single-search-result .phone-button {
  border-radius: 20px;
  margin-right: 10px;
}

.single-search-result .detail-button {
  border-radius: 20px;
}

.single-search-result .main-color {
  color: #fff;
  background: #0596de;
}

.single-search-result .main-color:hover {
  background: #5aa3ff;
}

.single-search-result .doctolib-button {
  border: 1px solid #0596de;
}

.single-search-result .doctolib-button:hover {
  border: 1px solid #5aa3ff;
}

@media (width <= 543px) {
  .single-search-result {
    padding-left: 35px;
    padding-right: 35px;
  }

  .single-search-result .buttons-container {
    text-align: center;
  }

  .single-search-result .buttons-container .phone-button {
    width: 100%;
    display: inline-block;
  }

  .single-search-result .buttons-container .detail-button {
    width: 100%;
    margin-top: 10px;
  }

  .single-search-result .horraire {
    display: block !important;
  }

  .single-search-result .simple-line-description {
    margin-top: -7px;
    margin-bottom: 15px !important;
  }
}

@media (width <= 767px) {
  .single-search-result .logo {
    text-align: center;
  }

  .single-search-result .logo:not(.no-logo) {
    height: 120px;
  }

  .single-search-result .logo .image-link {
    line-height: 110px;
  }
}

.single-search-result .logo img {
  width: 90%;
  height: auto;
  line-height: 20px;
}

@media (width <= 991px) {
  .single-search-result .logo img {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (width <= 767px) {
  .single-search-result .logo img {
    width: auto;
    max-height: 100px;
  }
}

.single-search-result .audiopro-name {
  color: #435f71;
  font-size: 18px;
  font-weight: 600;
  display: inline;
}

.single-search-result p {
  margin-top: 0;
}

.single-search-result .distance {
  color: #435f71;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (width <= 570px) {
  .single-search-result .distance {
    font-size: 16px;
  }
}

@media (width <= 1199px) {
  .single-search-result .distance {
    position: initial;
    text-align: right;
    margin-top: 10px;
    display: block;
  }
}

.single-search-result .search-address {
  color: #435f71;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

@media screen and (width <= 570px) {
  .single-search-result .search-address {
    font-size: 16px;
  }
}

.single-search-result .horraire {
  color: #0c0;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 6px;
  right: 15px;
}

@media screen and (width <= 570px) {
  .single-search-result .horraire {
    font-size: 16px;
  }
}

.single-search-result .horraire.opens-soon {
  color: #fe6618;
}

.single-search-result .horraire.does-not-open-soon {
  color: #7a7a7a;
  margin-right: 10px;
  font-weight: 500;
  text-decoration: none;
}

@media (width <= 1199px) {
  .single-search-result .horraire {
    position: initial;
    text-align: left;
    margin-top: 10px;
    display: block;
  }
}

@media (width <= 543px) {
  .single-search-result .horraire {
    position: initial;
    text-align: left;
    margin-top: 20px;
    display: inline-block;
  }
}

.single-search-result .distance-mobile {
  margin-top: 20px;
}

.single-search-result .personalized-offer {
  color: #ff0030;
  margin-top: -6px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (width <= 570px) {
  .single-search-result .personalized-offer {
    font-size: 16px;
  }
}

.single-search-result .personalized-offer a {
  color: #ff0030;
  text-decoration: none !important;
}

@media (width <= 1050px) {
  .pub-audika {
    display: none;
  }

  #map {
    height: 600px !important;
  }
}

.pub-audika {
  max-width: 300px;
  max-height: 250px;
}

.search--inner .pub-audika {
  margin-top: 20px;
}

.custom-texts strong {
  font-size: 15px;
  font-weight: 500;
}

.sidebar-d {
  width: 200px;
  height: calc(100% - 20px);
  margin-top: 21px;
  position: absolute;
  left: -210px;
}

.sidebar-d a {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 19px;
}

.sidebar-d a img {
  width: 200px;
  display: block;
}

@media (width <= 1640px) {
  .sidebar-d {
    display: none;
  }
}

.pub-audika {
  border-radius: 8px;
}

.orl-search-main-title {
  font-size: 24px;
}

.orl-search-sub-title {
  font-size: 16px;
}

.audiopro-search-main-title {
  font-size: 22px;
}

.audiopro-search-sub-title {
  font-size: 16px;
}

@media (width >= 992px) {
  .audiopro-search-main-title {
    max-width: 80%;
    margin: auto;
  }
}

@media (width <= 767px) {
  .single-search-result .logo .img-responsive[loading="lazy"] {
    max-width: 320px;
  }

  .single-search-result .logo .img-responsive[loading="lazy"].loaded {
    max-width: none;
  }
}

.cgu h1, .cgu h2, .cgu h3, .cgu h4, .cgv h1, .cgv h2, .cgv h3, .cgv h4, .legalmentions h1, .legalmentions h2, .legalmentions h3, .legalmentions h4 {
  margin-bottom: 10px;
  font-weight: normal !important;
}

.cgu h1, .cgv h1, .legalmentions h1 {
  margin-bottom: 30px;
  font-size: 28px;
}

.cgu h2, .cgv h2, .legalmentions h2 {
  font-size: 24px;
}

.cgu h3, .cgv h3, .legalmentions h3 {
  font-size: 22px;
}

.cgu h4, .cgv h4, .legalmentions h4 {
  font-size: 20px;
}

.login-tile {
  background: #fff;
  border-radius: 4px;
  padding: 40px 60px;
  box-shadow: 0 15px 35px #0e3a5b1a;
}

.login-tile .login-title {
  color: #435f71;
  font-size: 22px;
}

.login-tile .btn {
  font-size: 16px;
}

.login-tile input {
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #eceeef;
  border-radius: 0;
  margin-bottom: 18px;
}

.login-tile input:focus {
  border-bottom: 2px solid #328cff;
  margin-bottom: 17px;
}

.login-tile .form-group {
  position: relative;
}

.login-tile .form-group .inside-link {
  font-size: 14px;
  position: absolute;
  top: 6px;
  right: 0;
}

@media screen and (width <= 570px) {
  .login-tile .form-group .inside-link {
    font-size: 16px;
  }
}

.login-tile .cgus {
  padding: 0 10px;
}

.login-tile .cgus a {
  color: #435f71;
  margin-left: 5px;
  font-size: 14px;
  position: relative;
  top: 3px;
}

@media screen and (width <= 570px) {
  .login-tile .cgus a {
    font-size: 16px;
  }
}

.orl-speciality {
  background-color: #eef2f6;
  border-radius: 15px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  display: inline-block;
}

.doctolib-button {
  border-radius: 20px;
}

.center-listing {
  background-color: #f5faff;
}

.center-listing .listing-header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.center-listing .listing-header h1 {
  color: #818a91;
  font-size: 18px;
}

.center-listing .results {
  background: #fff;
  padding: 0 30px 30px;
}

.center-listing .results .center {
  margin-top: 30px;
}

.center-listing .results .center h2 {
  color: #328cff;
  font-size: 16px;
}

.center-listing .results .center .address {
  color: #435f71;
}

.center-listing .pager {
  text-align: center;
  margin-top: 10px;
}

.center-listing .pager a {
  margin: 5px;
}

.center-listing .pager a.current {
  color: #435f71;
  display: inline-block;
  position: relative;
}

.center-listing .pager a.current:after {
  content: "";
  background-color: #515151;
  border-radius: 5px;
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  top: 24px;
}

.center-listing .pager a.current:not(:first-child):after {
  left: 2px;
}

.center-listing .pager a.previous-results {
  float: left;
}

.center-listing .pager a.previous-results.disabled {
  color: #328cff;
  opacity: .4;
}

.center-listing .pager a.next-results {
  float: right;
}

.center-listing .pager a.next-results.disabled {
  color: #328cff;
  opacity: .4;
}

body.blog-list {
  background-color: #f5faff;
}

body.blog-list .back-link {
  color: #435f71;
  position: relative;
  top: -20px;
}

body.blog-list .back-link:hover {
  text-decoration: none;
}

body.blog-list .inner {
  background: #fff;
  padding: 60px 40px 50px;
}

body.blog-list .blog-title {
  color: #435f71;
  border-bottom: 1px solid #eceeef;
  margin-bottom: 25px;
  padding-bottom: 10px;
  font-size: 18px;
}

body.blog-list .single-article {
  margin-bottom: 20px;
}

body.blog-list .single-article h1 {
  color: #023866;
  font-size: 22px;
}

body.blog-list h2 {
  font-size: 20px;
  font-weight: bold;
}

body.blog-list .date {
  margin-bottom: 20px;
  font-size: 14px;
}

body.blog-list .articleline {
  margin-bottom: 20px;
}

body.blog-list .article-image {
  float: left;
  width: 100%;
  max-width: 100%;
}

body.blog-list .legend {
  text-align: center;
  font-size: 12px;
  font-style: italic;
}

@media screen and (width <= 570px) {
  body.blog-list .legend {
    font-size: 16px;
  }
}

body.blog-list .sidebar .sidebar-inner {
  width: 200px;
  margin: auto;
}

body.blog-list .readmore {
  font-size: 15px;
}

@media screen and (width <= 570px) {
  body.blog-list .readmore {
    font-size: 16px;
  }
}

body.blog-list .city-list {
  width: 300px;
}

body.blog-list .city-list a {
  color: #435f71;
  font-size: 15px;
}

@media screen and (width <= 570px) {
  body.blog-list .city-list a {
    font-size: 16px;
  }
}

body.blog-list .city-list li {
  margin-bottom: 6px;
}

body.blog-list hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.articlecontent h1 {
  color: #023866;
  font-size: 22px;
}

.articlecontent h2 {
  color: #023866;
  font-size: 17px;
  font-weight: 500;
}

.articlecontent h2 strong {
  font-size: 17px;
  font-weight: 500;
}

.articlecontent h3 {
  color: #023866;
  font-size: 16px;
}

.articlecontent p, .articlecontent ul, .articlecontent li {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (width <= 570px) {
  .articlecontent p, .articlecontent ul, .articlecontent li {
    font-size: 16px;
  }
}

.rss-icon {
  color: #afafaf;
}

.rss-icon:hover {
  color: #777;
}

.article-detail .step-container, .blog-list .step-container {
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

.article-detail .green-check, .blog-list .green-check {
  text-align: center;
  color: #fff;
  background-color: #17b355;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-weight: 700;
  line-height: 28px;
  transition: background-color .3s linear;
  position: absolute;
  top: 0;
  left: -14px;
}

.rdv-page .underlined-input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  width: 80%;
  margin: 10px auto 18px;
  padding: 5px;
  display: block;
}

.rdv-page .underlined-input:focus {
  border-bottom: 2px solid #328cff;
  margin-bottom: 17px;
}
/*# sourceMappingURL=app.css.map */
