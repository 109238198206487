body.search,
body.my-centers,
body.registration {
  background-color: $color-brand-gray-light;
  .search--form {
    background-color: $color-brand-blue;
    color: $color-light;
    background-color: #328cff;
    background-position: center center;
    background-size: cover;
    padding: 20px;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    &--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
    .search-explanatory-text {
      @include media-breakpoint-up(xl) {
        width: calc(100% - 700px);
        text-align: center;
      }
    }
    form {
      .form-group {
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      input {
        width: 190px;
        margin-right: 5px;
        padding: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 62.5px;
        @include media-breakpoint-up(xl) {
          width: 335px;
        }
        @include media-breakpoint-down(md) {
          text-align: left;
          display: inline-block;
        }
        @include media-breakpoint-down(sm) {
          width: calc(100% - 204px);
          margin: 0;
          display: inline-block;
        }
        @include media-breakpoint-down(xs) {
          vertical-align: middle;
          display: inline-block;
          width: calc(100% - 40px);
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }
      .audiopro-type-selector {
        cursor: pointer;
        height: 62.5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        border: none;
        border-right: 1px solid #ccc;
        max-width: 170px;
        @include media-breakpoint-down(xs) {
          display: block;
          width: 100%;
          max-width: 100%;
          margin-bottom: 10px;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
      #locate-me-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -7px;
        height: 62.5px;
        width: 40px;
        padding: 10px;
        background-color: #f5faff;
        color: $color-brand-gray;
        font-size: 15px;
        @media screen and (max-width: 570px) {
          font-size: 16px;
        }
        @include media-breakpoint-up(md) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &.disabled,
        &:disabled {
          cursor: default !important;
        }
      }
      #search-button {
        height: 62.5px;
        @include media-breakpoint-up(md) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }
        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }
        font-size: 18px;
      }
      .btn {
        padding: 15px 30px;
        font-size: 16px;
        font-family: "Montserrat", Helvetica, Arial, sans-serif;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
    h3,
    .fake-h3,
    .search-explanatory-text {
      color: #fff;
      font-size: 15px;
      @media screen and (max-width: 570px) {
        font-size: 16px;
      }
      margin-bottom: 0;
      font-weight: 300;
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }
  .search--inner {
    margin-bottom: 0px;
    padding-top: 0;
    padding-bottom: 20px;
    .search--map {
      width: 100%;
      .search--map--inner {
        height: calc(100vh - 176px);
        width: 100%;
        padding-top: 20px;
        iframe {
          height: 100%;
        }
      }
    }
  }
  .image-link {
    line-height: 150px;
  }
  #map {
    position: relative;
    margin-top: 28px;
    overflow: hidden;
    height: 500px;
    border-radius: 8px;
  }
  #map-container,
  .sticky-wrapper {
    width: 100%;
    z-index: 10;
    position: sticky;
    top: 19px;
  }
  .z0 {
    z-index: 0;
  }
  .refine-search-block {
    a {
      font-size: 14px;
      @media screen and (max-width: 570px) {
        font-size: 16px;
      }
      line-height: 1.714;
      color: $gray;
      margin: 1.2rem 0;
    }
    margin-top: 20px;
  }
  .disclaimer {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
  #address-input {
    border-color: white;
  }
  .pager {
    text-align: center;
    margin-top: 10px;

    .pager-item {
      /* Make links bigger on mobile so they're easier to click*/
      @media screen and (max-width: 570px) {
        font-size: 24px;
      }
    }

    a {
      margin: 5px;
    }
    a.current {
      color: $gray;
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        border-radius: 5px;
        width: 5px;
        height: 5px;
        background-color: #515151;
        position: absolute;
        top: 24px;
        display: block;

        @media screen and (max-width: 570px) {
          top: 33px;
        }
      }
      &:not(:first-child) {
        &::after {
          left: 2px;

          @media screen and (max-width: 570px) {
            left: 5px;
          }
        }
      }
    }
    a.previous-results {
      float: left;
      &.disabled {
        color: $color-brand-blue;
        opacity: 0.4;
      }
    }
    a.next-results {
      float: right;
      &.disabled {
        color: $color-brand-blue;
        opacity: 0.4;
      }
    }
  }
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.sticky-wrapper {
  z-index: 0;
}

.single-search-result {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  border-color: transparent;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid transparent;
  &.active {
    -webkit-box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    -moz-box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    border: 1px solid $color-brand-blue;
  }
  &:hover {
    -webkit-box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    -moz-box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    border: 1px solid white;
  }
  &:last-child {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .telephone {
    height: 32px;
    line-height: 30px;
    text-align: center;
    position: relative;
    top: 2px;
  }
  &.vedette {
    border: 1px solid #0596de;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 35px;
    padding-right: 35px;
    .horraire {
      display: block !important;
    }
    .simple-line-description {
      margin-top: -7px;
      margin-bottom: 15px !important;
    }
  }

  .rounded {
  }

  .phone-button {
    border-radius: 20px;
    margin-right: 10px;
  }

  .detail-button {
    border-radius: 20px;
  }

  .main-color {
    background: #0596de;
    color: white;
    &:hover {
      background: #5AA3FF;
    }
  }

  .doctolib-button {
    border: 1px solid #0596de;
    &:hover {
      border: 1px solid #5AA3FF;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-left: 35px;
    padding-right: 35px;
    .buttons-container {
      text-align: center;
      .phone-button {
        display: inline-block;
        width: 100%;
      }
      .detail-button {
        width: 100%;
        margin-top: 10px;
      }
    }
    .horraire {
      display: block !important;
    }
    .simple-line-description {
      margin-top: -7px;
      margin-bottom: 15px !important;
    }
  }
  .logo {
    @include media-breakpoint-down(sm) {
      text-align: center;
      &:not(.no-logo) {
        height: 120px;
      }
    }
    @include media-breakpoint-down(sm) {
      .image-link {
        line-height: 110px;
      }
    }
    img {
      line-height: 20px;
      width: 90%;
      height: auto;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 10px;
      }
      @include media-breakpoint-down(sm) {
        max-height: 100px;
        width: auto;
      }
    }
  }
  .audiopro-name {
    color: $color-brand-gray;
    font-size: 18px;
    display: inline;
    font-weight: 600;
  }
  p {
    margin-top: 0;
  }
  .btn {
  }
  .distance {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $color-brand-gray;
    font-size: 14px;
    @media screen and (max-width: 570px) {
      font-size: 16px;
    }
    @include media-breakpoint-down(lg) {
      position: initial;
      display: block;
      text-align: right;
      margin-top: 10px;
    }
  }
  .search-address {
    margin-top: 10px;
    line-height: 23px;
    font-weight: 400;
    font-size: 14px;
    @media screen and (max-width: 570px) {
      font-size: 16px;
    }
    margin-bottom: 20px;
    color: $gray;
  }
  .horraire {
    position: absolute;
    right: 15px;
    bottom: 6px;
    font-size: 14px;
    @media screen and (max-width: 570px) {
      font-size: 16px;
    }
    color: #0c0;
    font-weight: 500;
    text-align: right;
    &.opens-soon {
      color: #fe6618;
    }
    &.does-not-open-soon {
      color: #7a7a7a;
      font-weight: 500;
      margin-right: 10px;
      text-decoration: none;
    }
    @include media-breakpoint-down(lg) {
      position: initial;
      display: block;
      text-align: left;
      margin-top: 10px;
    }
    @include media-breakpoint-down(xs) {
      position: initial;
      display: inline-block;
      text-align: left;
      margin-top: 20px;
    }
  }
  .distance-mobile {
    margin-top: 20px;
  }
  .personalized-offer {
    margin-top: -6px;
    margin-bottom: 15px;
    color: #ff0030;
    font-size: 14px;
    @media screen and (max-width: 570px) {
      font-size: 16px;
    }
    font-weight: 500;
    a {
      color: #ff0030;
      text-decoration: none !important;
    }
  }
}

@media (max-width: 1050px) {
  .pub-audika {
    display: none;
  }
  #map {
    height: 600px !important;
  }
}

.pub-audika {
  max-width: 300px;
  max-height: 250px;
}

.search--inner {
  .pub-audika {
    margin-top: 20px;
  }
}

.custom-texts {
  strong {
    font-weight: 500;
    font-size: 15px;
  }
}

.sidebar-d {
  position: absolute;
  left: -210px;
  width: 200px;
  height: calc(100% - 20px);
  margin-top: 21px;

  a {
    position: sticky;
    top: 19px;
    display: block;
    img {
      width: 200px;
      display: block;
    }
  }
}

@media (max-width: 1640px) {
  .sidebar-d {
    display: none;
  }
}

.additional-pricing-text {
}

.pub-audika {
  border-radius: 8px;
}


.orl-search-main-title{
  font-size: 24px;
}
.orl-search-sub-title{
  font-size: 16px;
}

.audiopro-search-main-title{
  font-size: 22px;
}
.audiopro-search-sub-title{
  font-size: 16px;
}

@include media-breakpoint-up(lg) {
  .audiopro-search-main-title{
    max-width: 80%;
        margin: auto;
  }        
}


@include media-breakpoint-down(sm) {
  .single-search-result .logo{
      .img-responsive[loading=lazy]{
          max-width: 320px;
  
          &.loaded{
              max-width: none;
          }
      }
  }        
}
