.cgu,
.cgv,
.legalmentions {
    h1,h2,h3,h4{
        font-weight: normal !important;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 28px;
        margin-bottom: 30px;
    }

    h2{
        font-size: 24px;
    }

    h3{
        font-size: 22px;
    }

    h4{
        font-size: 20px;
    }
}