.block_helper{
  background-color: $color-brand-blue;
  color: $color-light;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding: ($spacer * 1) 0 ;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 16px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  h3, h4, h5, a, .fake-footer-h4{
    color: $color-light;
    padding: 5px;

  }
  h3,h4, h5, .fake-footer-h4{
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
  a{
    font-size: 14px;
    @media screen and (max-width: 570px) {
        font-size: 16px;
    }
  }

}