body.blog-list {
    .back-link {
        position: relative;
        top: -20px;
        color: $gray;
        &:hover {
            text-decoration: none;
        }
    }
    background-color: #F5FAFF;
    .inner {
        background: white;
        padding: 60px 40px 50px 40px;
    }
    .blog-title {
        font-size: 18px;
        color: $gray;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-lighter;
        margin-bottom: 25px;
    }
    .single-article {
        margin-bottom: 20px;
        h1 {
            font-size: 22px;
            color: #023866;
        }
    }
    h2 {
        font-size: 20px;
        font-weight: bold;
    }
    .date {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .articleline {
        margin-bottom: 20px;
    }
    .article-image {
        float: left;
        width: 100%;
        max-width: 100%;
    }
    .legend {
        font-size: 12px;
        @media screen and (max-width: 570px) {
            font-size: 16px;
        }
        font-style: italic;
        text-align: center;
    }
    .sidebar {
        .sidebar-inner {
            width: 200px;
            margin: auto;
        }
    }
    .readmore {
        font-size: 15px;
        @media screen and (max-width: 570px) {
            font-size: 16px;
        }
    }
    .city-list {
        width: 300px;
        a {
            color: $gray;
            font-size: 15px;
            @media screen and (max-width: 570px) {
                font-size: 16px;
            }
        }
        li {
            margin-bottom: 6px;
        }
    }
    hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.articlecontent {
    h1 {
        font-size: 22px;
        color: #023866;
    }
    h2 {
        font-size: 17px;
        color: #023866;
        font-weight: 500;

        strong{
            font-size: 17px;
            font-weight: 500;
        }
    }
    h3 {
        font-size: 16px;
        color: #023866;
    }
    p,
    ul,
    li {
        margin-top: 0px;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 14px;
        @media screen and (max-width: 570px) {
            font-size: 16px;
        }
    }
}

.rss-icon{
    color:#afafaf;
    &:hover{
        color:#777777;
    }
}

.article-detail, .blog-list {
    .step-container {
      padding-left: 12px;
      padding-right: 12px;
      position: relative;
    }
    .green-check {
      width: 28px;
      height: 28px;
      text-align: center;
      color: #fff;
      background-color: #17b355;
      border-radius: 50%;
      font-weight: 700;
      line-height: 28px;
      transition: background-color 0.3s linear;
      position: absolute;
      top: 0;
      left: -14px;
    }
  }
  