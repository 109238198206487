.login-tile {
  .login-title {
    font-size: 22px;
    color: #435f71;
  }
  background: white;
  padding: 40px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 4px;
  box-shadow: 0 15px 35px 0 rgba(14, 58, 91, 0.1);

  .btn{
    font-size: 16px;
  }

  input {
    border: none;
    border-bottom: 1px solid $gray-lighter;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 18px;
    &:focus {
      border-bottom: 2px solid $color-brand-blue;
      margin-bottom: 17px;
    }
  }
  .form-group {
    position: relative;
    .inside-link {
      position: absolute;
      right: 0;
      top: 6px;
      font-size: 14px;
      @media screen and (max-width: 570px) {
          font-size: 16px;
      }
    }
  }
  .cgus {
    padding: 0 10px 0 10px;
    a {
      margin-left: 5px;
      font-size: 14px;
      @media screen and (max-width: 570px) {
          font-size: 16px;
      }
      color: $gray;
      position: relative;
      top: 3px;
    }
  }
}
