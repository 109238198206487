.carousel {
  position: relative;
  display: flex;
  height: 100%;
}

.carousel-item {
  background-size: cover;
  background-position: top center;
  height: 100%;
  .carousel-caption {
    display: flex !important;
    align-items: center;
    justify-content: center;
    h1 {
      color: $color-light;
      @include media-breakpoint-down(md) {
        font-size: 30px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 27px;
      }
    }
  }
  .container {
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: 100% bottom;
    background-size: 24%;

    @media screen and (max-width: 980px) {
      background-position: 100% bottom;
    }
  }
  /*     h1,
    h2 {
        text-shadow: 0px 0px 1px #010101;
    } */
}

.carousel-control-prev,
.carousel-control-next {
  span {
    background-color: $color-brand-green;
    padding: 20px;
    background-size: 15px;
  }
}

.carousel-control-next {
  right: 0;
  justify-content: flex-end;
  span {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 12px;
    @media screen and (max-width: 570px) {
      font-size: 16px;
    }
  }
}

.carousel-control-prev {
  right: 0;
  justify-content: flex-start;
  span {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.home-hero-subtext {
  margin-bottom: 98px;

  @media screen and (max-width: 576px) {
    width: 80%;
  }
}

body.home #address-input {
  @media screen and (min-width: 1200px) {
    width: 470px;
  }

  @media screen and (max-width: 1200px) {
    width: 320px;
  }

  @media screen and (max-width: 992px) {
    width: 200px;
  }
  /*   
  @media screen and (max-width: 768px) {
    width: 320px;
  }
  
  @media screen and (max-width: 576px) {
    width: 320px;
  } */
}
