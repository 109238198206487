.modal-green-check {
  color: white;

  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  font-weight: 700;
  line-height: 28px;
  border-radius: 50%;
  background-color: #17b355;
  color: #fff;
  transition: background-color 0.3s linear;
  margin-right: 30px;
}
