.pointer{
  cursor: pointer;
}

.no-transitions{
  transition: none;
}

.no-decorations{
  text-decoration: none !important;
}

@for $i from 0 through 30 {
  .p-#{$i*5} {
    padding: 5px * $i !important;
  }

  .pt-#{$i*5} {
    padding-top: 5px * $i !important;
  }

  .pb-#{$i*5} {
    padding-bottom: 5px * $i !important;
  }

  .pl-#{$i*5} {
    padding-left: 5px * $i !important;
  }

  .pr-#{$i*5} {
    padding-right: 5px * $i !important;
  }

}

@for $i from 0 through 30 {
  .m-#{$i*5} {
    margin: 5px * $i !important;
  }

  .mt-#{$i*5} {
    margin-top: 5px * $i !important;
  }

  .mb-#{$i*5} {
    margin-bottom: 5px * $i !important;
  }

  .ml-#{$i*5} {
    margin-left: 5px * $i !important;
  }

  .mr-#{$i*5} {
    margin-right: 5px * $i !important;
  }

}