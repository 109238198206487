.rdv-page {
  .underlined-input {
    width: 80%;
    display: block;
    margin: auto;
    margin-top: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 18px;
    &:focus {
      border-bottom: 2px solid $color-blue-prices;
      margin-bottom: 17px;
    }
  }
}
