.center-listing {
    background-color: $color-brand-gray-light;
    .listing-header {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        h1 {
            font-size: 18px;
            color: $gray-light;
        }
    }
    .results {
        background: white;
        padding: 0 30px 30px 30px;
        .center {
            margin-top: 30px;
            h2 {
                color:#328CFF;
                font-size: 16px;
            }
            .address{
                color:#435f71;
            }
        }
    }

    .pager {
        text-align: center;
        margin-top: 10px;
        a {
            margin: 5px;
        }
        a.current {
            color: $gray;
            position: relative;
            display: inline-block;
            &::after {
                content: '';
                border-radius: 5px;
                width: 5px;
                height: 5px;
                background-color: #515151;
                position: absolute;
                top: 24px;
                display: block;
            }
            &:not(:first-child) {
                &::after {
                    left: 2px;
                }
            }
        }
        a.previous-results {
            float: left;
            &.disabled {
                color: $color-brand-blue;
                opacity: 0.4;
            }
        }
        a.next-results {
            float: right;
            &.disabled {
                color: $color-brand-blue;
                opacity: 0.4;
            }
        }
    }
}