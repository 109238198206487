.container-full {
  width: 100%;
  padding: 0 85px;
}

iframe {
  width: 100%;
  height: auto;
}

hr.clean {
  border-color: transparent !important;
}

.icone_home {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  margin: auto;
}

.heading--title {
  padding: 90px 0;
  background-color: $color-brand-blue;
  text-align: center;
  margin-bottom: 20px;
  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 30px 0;
  }
  h1 {
    color: $color-light;
  }
  .lead {
    color: $color-light;
    a {
      color: $color-light;
    }
  }
}

section {
  padding: ($spacer * 4) 0;
}

.hero {
  background-color: rgb(247, 249, 253);
  color: $color-brand-gray;
  background-position: center center;
  background-size: cover;
  h1,
  h2, .h2,
  h3,
  h4,
  .lead,
  p {
    color: $color-brand-gray;
  }
}

.title-borderfill {
  position: relative;
  padding-bottom: 20px;
  font-weight: 300;
  &:after {
    content: "";
    width: 100px;
    height: 3px;
    background-color: $color-brand-green;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50px;
  }
}

.title-border {
  position: relative;
  padding-bottom: 20px;
  font-weight: 300;
}

.card {
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
    -1px 0 0 rgba(0, 0, 0, 0.03), 1px 0 0 rgba(0, 0, 0, 0.03),
    0 1px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), -1px 0 0 rgba(0, 0, 0, 0.03),
    1px 0 0 rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), -1px 0 0 rgba(0, 0, 0, 0.03),
    1px 0 0 rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.12);
  &--title {
    color: $color-brand-gray;
    font-weight: 300;
    position: relative;
    padding-bottom: 10px;
    display: inline-block;
    &:after {
      content: "";
      border-bottom: 1px solid $color-brand-gray-light;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
  &--content {
    min-height: 320px;
    margin-bottom: 20px;
    padding: 10px 30px;
    .logo {
    }
  }
  &--small-content {
    min-height: 120px;
    margin-bottom: 20px;
    padding: 10px 30px;
  }
  &--photo {
    padding: 5px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &--map {
    @include media-breakpoint-up(sm) {
      min-height: 320px;
    }
    margin-bottom: 20px;
    &_title {
      color: $color-brand-gray;
      font-weight: 300;
    }
    iframe {
      width: 100%;
      height: 200px;
    }
  }
}

.notif {
  border-radius: 3px;
  padding: 10px;
  border-left: 3px solid #00d9d9 !important;
  background-color: white; // max-width: calc(100% - 30px);
  // margin-left: 15px;
  .float-right {
    @include media-breakpoint-down(md) {
      float: none !important;
    }
  }
}

.fil-ariane {
  p {
    font-size: 14px;
    @media screen and (max-width: 570px) {
      font-size: 16px;
    }
  }
  a:hover {
    text-decoration: none;
  }
}

.hover-white {
  &:hover {
    background: white !important;
    color: $color-brand-gray !important;
  }
}

.hover-blue-border {
  &:hover {
    border-color: white !important;
    background-color: white !important;
    color: $color-brand-gray !important;
  }
}

.scrolled {
  .hover-blue-border {
    &:hover {
      border-color: $color-brand-blue !important;
      color: $color-brand-blue !important;
    }
  }
}

.btn {
  transition: none;
  cursor: pointer;
  box-shadow: none !important;
}

.focus-white:focus {
  color: white;
}

.btn-40 {
  height: 40px;
  padding: 8px 16px;
}

.not-bold {
  font-weight: normal;
}

a.no-hover:hover {
  text-decoration: none;
  color: $color-brand-blue;
}

a.no-hover:focus {
  text-decoration: none;
}

.default-color {
  color: $color-brand-gray;
}

.body-color {
  color: $gray-dark;
}

.justify-center {
  justify-content: center;
}

.greybg {
  background-color: $color-brand-gray-light;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.btn {
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn-primary:hover {
  background: #99d733;
  border-color: #99d733;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-outline-secondary:active,
.btn-outline-secondary:hover {
  background: #5AA3FF;
  border-color: #5AA3FF;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  background: white !important;
  color: #5AA3FF !important;
}

.btn-secondary:active {
  background: #5AA3FF;
  border-color: #5AA3FF;
}

.btn-primary:focus {
  color: white;
}

.btn-secondary:focus {
  color: white;
}

.btn-danger:focus {
  color: white;
}

.table.no-header-first-line {
  tr th {
    border-top: none;
  }
}

.btn-primary:active {
  color: #fff;
  background-color: $color-brand-green;
  border-color: $color-brand-green;
}

.inherit-color {
  color: inherit;
}

.card {
  box-shadow: none;
  border: 0px;
  border-radius: 3px;
}

p.reduced-magins {
  margin: 8px 0;
}

.weight-normal {
  font-weight: normal;
}

.weight-light {
  font-weight: 300;
}

.weight-semi-light {
  font-weight: 400;
}

.w-100percent {
  width: 100%;
}

.m-auto {
  margin: auto;
}

.btn {
  font-size: 15px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 1.25rem;
}

.form-control {
  transition: 0s;
}

.my-account-dropdown-content,
.country-dropdown-content {
  -webkit-box-shadow: 0px 6px 13px 2px rgba(176, 176, 176, 0.32);
  -moz-box-shadow: 0px 6px 13px 2px rgba(176, 176, 176, 0.32);
  box-shadow: 0px 6px 13px 2px rgba(176, 176, 176, 0.32);
  .top-triangle {
    position: absolute;
    top: -11px;
    left: 21px;
    polygon {
    }
    path {
      fill: white;
      stroke: #e1e9f0;
      stroke-width: 1;
    }
  }
}

.country-dropdown-content {
  .top-triangle {
    left: 15px;
  }
  .flag-item {
    position: relative;
    top: 3px;
    margin-right: 7px;
  }
}

.blue-prices {
  color: $color-blue-prices;
  font-weight: 600;
  strong {
    font-weight: 600;
  }
}

.single-search-result-prices{
  margin-top: 11px !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
}

.text-ad-links {
  // Removes weird white banner in the bottom of the screen
  display: none;
}

// Lazy loading
img {
  opacity: 1;
  transition: opacity 0.3s;
}

img[data-src]:not([src]) {
  opacity: 0;
}

.dark-blue-button {
  color: white;
  background-color: #001d55 !important;
  border: 1px solid #001d55 !important;
  &:hover {
    color: white !important;
    background-color: #001d55 !important;
    border: 1px solid #001d55 !important;
  }
  &.rounded {
    border-radius: 25px;
  }
}


.blue-faded-button {
  color: #1a73e8 !important;
  background-color: #e8f0fe !important;
  border: 1px solid #e8f0fe !important;
  &:hover {
    color: #1a73e8 !important;
    background-color: #dbe8ff !important;
    border: 1px solid #dbe8ff !important;
  }
  &.rounded {
    border-radius: 25px;
  }
}

.yellow-button {
  color: #435f71;
  background-color: #fdc400 !important;
  border: 1px solid #fdc400 !important;
  border-radius: 5px;
  &:hover,
  &:focus,
  &.active {
    color: #435f71 !important;
    background-color: #fdc400 !important;
    border: 1px solid #fdc400 !important;
  }
  &.rounded {
    border-radius: 25px;
  }
}
.phone-button {
  &.rounded {
    border-radius: 25px;
  }
}

.my-account-button {
  border-width: 2px !important;
  border-radius: 25px !important;
}

input.simple-search-input {
  border: 1px solid #e1e9f0;

  @include media-breakpoint-down(xs) {
    width: 320px !important;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
}

.shadow-card {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.custom-checkbox .custom-control-indicator,
.custom-radio .custom-control-indicator {
  background-color: white;
  border: 1px solid #aaa;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  border: none;
  background-color: #0275d8;
}

.btn {
  padding-top: 10px;
  padding-bottom: 10px;
}

.blue-header {
  background-color: #328CFF;
  color: white;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 4px 6px #435f717a;
  border-bottom: none;

  button.close {
    position: absolute;
    right: 18px;
    top: 15px;
    color: white;
    opacity: 0.8;
  }
}

.modal-title {
  color: #0275d8 !important;
  font-size: 18px;
}

.rounded-20 {
  border-radius: 20px;
}

.weight-600 {
  font-weight: 600;
}


.xl-rounded {
  border-radius: 25px;
}

.no-hover{
  &:hover{
    text-decoration: none;
  }
}

button.reset {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

h3.reset{
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 100%;
  color: inherit;
  font-family: inherit;
  line-height: 24px;
}