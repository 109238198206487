.header {
    background-color: #fff;
    height: 58px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px 0 rgba(67, 95, 113, 0.2);
    position: relative;
    z-index: 1;
    .container {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    .header--inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: inline-block;
            width: 100%;
        }
        #logo {
            width: 190px;
            height: auto;
            position: relative;
            top: 2px;
            @include media-breakpoint-down(md) {
                float: left;
            }
            svg {
                height: auto;
                width: auto;
            }
        }
        .mainnav {
            background-color: #fff;
            padding-right: 0;
            @include media-breakpoint-down(md) {
                background-color: transparent;
                .navbar-toggler {
                    margin-top: -18px;
                    border: 1px solid $color-brand-gray;
                    padding: 5px 10px;
                    span {
                        background-color: $color-brand-gray;
                        width: 25px;
                        height: 2px;
                        display: block;
                        margin: 6px 0;
                    }
                }
                .navbar-collapse {
                    background-color: #fff;
                    padding: 20px;
                    position: fixed;
                    width: 100%;
                    left: 0;
                    top: 58px;
                    text-align: center;
                }
            }
            ul {
                margin: 0;
                padding: 0;
                @include media-breakpoint-down(md) {
                    align-items: center;
                }
                li {
                    list-style: none;
                    display: inline-flex;
                    a:not(.dropdown-item) {
                        padding: 8px 16px;
                        color: $color-brand-gray;
                        font-size: 15px;
                        @media screen and (max-width: 570px) {
                            font-size: 16px;
                        }
                        position: relative;
                        &.border--btn {
                            border: 1px solid $color-brand-gray;
                            border-radius: 4px;
                            margin-left: 16px;
                            &:after {
                                display: none;
                            }
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            left: 16px;
                            bottom: 0px;
                            width: calc(100% - 32px);
                            height: 2px;
                            background-color: $color-brand-gray;
                            opacity: 0;
                        }
                        &:not(.my-account-dropdown) {
                            &:hover {
                                color: $color-brand-gray;
                                &:not(.flag-link):after {
                                    opacity: 1;
                                    @include media-breakpoint-down(md) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.my-account-dropdown {
    cursor: pointer;
    &:hover {
        border: none;
    }
}

.my-account-dropdown-content, .country-dropdown-content {
    border:1px solid #e1e9f0 !important;
    margin-top: 15px;
    a {
        color: $color-brand-gray;
        font-size: 15px;
        @media screen and (max-width: 570px) {
            font-size: 16px;
        }
    }
    a:hover {
        background-color: $color-brand-blue-logo;
        color: white !important;
    }
    .dropdown-item {
        padding: 7px 1rem;
    }
}

.flag-link {
    padding: 0 !important;
    &:hover {
        border: none !important;
    }
    svg {
        margin: 9px;
        enable-background: new 0 0 512 512;
        margin-bottom: 0px;
    }
}

.dropdown-menu {
    border: 1px solid rgba(67, 95, 113, .2);
}