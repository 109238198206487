h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-brand-blue;
}

p {
  font-size: 14px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
  line-height: 1.714;
  color: $gray;
  margin: 1.2rem 0;
  font-weight: 300;
}

a,
.btn-link {
  color: $color-brand-blue;
}

.color-gray {
  color: $gray;
}

.color-blue {
  color: $color-brand-blue;
  display: block;
}

.weight-semi-bold {
  font-weight: 500;
}

.weight-semi-light {
  font-weight: 400;
}

.fake-h1 {
  font-size: 14px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
  line-height: 1.714;
  color: $gray;
  margin: 1.2rem 0;
}

.fake-h2 {
  font-size: 14px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
  line-height: 1.714;
  color: $gray;
  margin-top: -15px;
}

.fake-h5 {
  font-size: 20px;
  font-size: 1.25rem;
}

.fake-h3 {
  color: $color-brand-blue;
  display: block;
  font-size: 28px;
  font-size: 1.75rem;
}

.font-larger {
  font-size: 16px;
  font-weight: normal;
}

.font-standard {
  font-size: 14px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
}
.font-larger-bold {
  font-size: 16px;
  font-weight: 600;
}

.font-medium-bold {
  font-size: 15px;
  font-weight: bold;
}
.font-medium {
  font-size: 15px;
}
.font-small {
  font-size: 12px;
}

.lead {
  font-size: 15px;
  @media screen and (max-width: 570px) {
    font-size: 16px;
  }
}

.font-bigger {
  font-size: 18px;
}
