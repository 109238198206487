.orl-speciality {
  margin-right: 10px;
  margin-top: 5px;
  background-color: #eef2f6;
  border-radius: 15px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  display: inline-block;
  font-size: 14px;
}


.doctolib-button{
  border-radius:20px;
}